

import { Options, Vue, Prop, Watch } from "vue-decorator";
import ConfirmArguments, { ConfirmPlugin } from '@/plugins/Confirm';


@Options({})
export default class Confirm extends Vue {
    private static DefaultArgs: ConfirmArguments = {
        title: null,
        message: "Are you sure?",
        confirmAlias: "Confirm",
        cancelAlias: "Cancel"
    };

    showModal = false;
    args = Confirm.DefaultArgs;
    title: string | null = null;
    messages: string[] = [];
    pendingConfirm: Promise<boolean> = Promise.resolve<boolean>(true);

    resolver: ((v: boolean) => void) | null = null;

    created() {
        ConfirmPlugin.confirmAction = this.prompt;
    }

    public prompt(args: ConfirmArguments | null) {
        this.pendingConfirm = new Promise<boolean>((resolve, reject) => {
            this.resolver = resolve;
        });
        
        this.args = Object.assign({}, Confirm.DefaultArgs, args);

        this.title = args?.title ?? null;

        if(args?.message) {
            this.messages = args?.message.split("\n");
        } else {
            this.messages = [args?.message!];
        }

        this.showModal = true;
        return this.pendingConfirm;
    }

    public cancel() {
        if(this.resolver)
            this.resolver(false);

        this.showModal = false;
    }

    public confirm() {
        if(this.resolver)
            this.resolver(true);

        this.showModal = false;
    }

    public overlayCancel(e: InputEvent) {
        if((e.target as HTMLElement).classList.contains("overlay")) {
            this.cancel();
        }
    }
}

