
import { Options, Vue } from 'vue-class-component';
import ResetPassword from '@/components/Auth/ResetPassword.vue';

@Options({
  components: {
      ResetPassword
  },
})
export default class Login extends Vue 
{
    created() {
        if(this.$store.state.auth.isAuthenticated) {
            this.$router.push("/");
        }
    }
}
