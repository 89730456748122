/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IntegrateSteamRequest } from '../models/IntegrateSteamRequest';
import type { IntegrateYoutubeRequest } from '../models/IntegrateYoutubeRequest';
import type { IntegrationVideoInfo } from '../models/IntegrationVideoInfo';
import { request as __request } from '../core/request';

export class IntegrationsService {

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static async linkDiscord(
requestBody?: string,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/api/Integrations/discord`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static async unlinkDiscord(): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/api/Integrations/discord/unlink`,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static async linkTwitch(
requestBody?: string,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/api/Integrations/twitch`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static async unlinkTwitch(): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/api/Integrations/twitch/unlink`,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static async linkYoutube(
requestBody?: IntegrateYoutubeRequest,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/api/Integrations/youtube`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static async unlinkYoutube(): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/api/Integrations/youtube/unlink`,
        });
        return result.body;
    }

    /**
     * @returns IntegrationVideoInfo Success
     * @throws ApiError
     */
    public static async youtubeRecentUploads(): Promise<Array<IntegrationVideoInfo>> {
        const result = await __request({
            method: 'GET',
            path: `/api/Integrations/youtube/recentUploads`,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static async linkSteam(
requestBody?: IntegrateSteamRequest,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/api/Integrations/steam`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static async unlinkSteam(): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/api/Integrations/steam/unlink`,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static async linkXbox(
requestBody?: string,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/api/Integrations/xbox`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static async unlinkXbox(): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/api/Integrations/xbox/unlink`,
        });
        return result.body;
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static async testStreamlist(): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/api/Integrations/streamlist`,
        });
        return result.body;
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static async testStreamlist1(): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/api/Integrations/streamlist`,
        });
        return result.body;
    }

}