import { toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-090d1869"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "breadcrumb-item" }
const _hoisted_2 = { class: "select" }
const _hoisted_3 = ["value"]
const _hoisted_4 = { class: "breadcrumb-item" }
const _hoisted_5 = { class: "select" }
const _hoisted_6 = ["value"]
const _hoisted_7 = {
  key: 0,
  class: "separator",
  disabled: "disabled"
}
const _hoisted_8 = ["value"]
const _hoisted_9 = { class: "breadcrumb-item" }
const _hoisted_10 = { class: "select" }
const _hoisted_11 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("li", _hoisted_1, [
      _createElementVNode("span", _hoisted_2, [
        _createTextVNode(_toDisplayString(_ctx.modelValue.game.Name) + " ", 1),
        _withDirectives(_createElementVNode("select", {
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modelValue.game) = $event)),
          onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.gameChange && _ctx.gameChange(...args)))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.games, (game) => {
            return (_openBlock(), _createElementBlock("option", {
              key: game.Id,
              value: game
            }, _toDisplayString(game.Name), 9, _hoisted_3))
          }), 128))
        ], 544), [
          [_vModelSelect, _ctx.modelValue.game]
        ])
      ])
    ]),
    _createElementVNode("li", _hoisted_4, [
      _createElementVNode("span", _hoisted_5, [
        _createTextVNode(_toDisplayString(_ctx.modelValue.category.Name) + " ", 1),
        _withDirectives(_createElementVNode("select", {
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.modelValue.category) = $event)),
          onChange: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.categoryChange && _ctx.categoryChange(...args)))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.normalCats, (category) => {
            return (_openBlock(), _createElementBlock("option", {
              key: category.Id,
              value: category
            }, _toDisplayString(category.Name), 9, _hoisted_6))
          }), 128)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.extensionCats, (category, i) => {
            return (_openBlock(), _createElementBlock(_Fragment, {
              key: category.Id
            }, [
              (i == 0)
                ? (_openBlock(), _createElementBlock("option", _hoisted_7, "Extras"))
                : _createCommentVNode("", true),
              _createElementVNode("option", { value: category }, _toDisplayString(category.Name), 9, _hoisted_8)
            ], 64))
          }), 128))
        ], 544), [
          [_vModelSelect, _ctx.modelValue.category]
        ])
      ])
    ]),
    _createElementVNode("li", _hoisted_9, [
      _createElementVNode("span", _hoisted_10, [
        _createTextVNode(_toDisplayString(_ctx.modelValue.difficulty) + " ", 1),
        _withDirectives(_createElementVNode("select", {
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.modelValue.difficulty) = $event)),
          onChange: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.difficultyChange && _ctx.difficultyChange(...args)))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modelValue.category?.Difficulties, (diff) => {
            return (_openBlock(), _createElementBlock("option", {
              key: diff,
              value: diff
            }, _toDisplayString(diff), 9, _hoisted_11))
          }), 128))
        ], 544), [
          [_vModelSelect, _ctx.modelValue.difficulty]
        ])
      ])
    ])
  ], 64))
}