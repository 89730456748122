/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AccountStatus } from '../models/AccountStatus';
import type { AccountSummaryPagedResult } from '../models/AccountSummaryPagedResult';
import type { ChangeUsernameRequest } from '../models/ChangeUsernameRequest';
import type { DeleteUserAccountRequest } from '../models/DeleteUserAccountRequest';
import type { ExportUserDataRequest } from '../models/ExportUserDataRequest';
import type { UserAccount } from '../models/UserAccount';
import { request as __request } from '../core/request';

export class AccountsService {

    /**
     * @param page 
     * @param search 
     * @param accountStatus 
     * @returns AccountSummaryPagedResult Success
     * @throws ApiError
     */
    public static async pageAccounts(
page: number,
search?: string,
accountStatus?: AccountStatus,
): Promise<AccountSummaryPagedResult> {
        const result = await __request({
            method: 'GET',
            path: `/api/Accounts`,
            query: {
                'page': page,
                'search': search,
                'accountStatus': accountStatus,
            },
        });
        return result.body;
    }

    /**
     * @param userId 
     * @returns UserAccount Success
     * @throws ApiError
     */
    public static async getUserAccount(
userId: string,
): Promise<UserAccount> {
        const result = await __request({
            method: 'GET',
            path: `/api/Accounts/${userId}`,
        });
        return result.body;
    }

    /**
     * @param userId 
     * @param requestBody 
     * @returns UserAccount Success
     * @throws ApiError
     */
    public static async updatePermissions(
userId: string,
requestBody?: UserAccount,
): Promise<UserAccount> {
        const result = await __request({
            method: 'PUT',
            path: `/api/Accounts/${userId}/permissions`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param userId 
     * @param requestBody 
     * @returns UserAccount Success
     * @throws ApiError
     */
    public static async updateModeratingGames(
userId: string,
requestBody?: UserAccount,
): Promise<UserAccount> {
        const result = await __request({
            method: 'PUT',
            path: `/api/Accounts/${userId}/moderating`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param userId 
     * @param requestBody 
     * @returns UserAccount Success
     * @throws ApiError
     */
    public static async updateStatus(
userId: string,
requestBody?: UserAccount,
): Promise<UserAccount> {
        const result = await __request({
            method: 'PUT',
            path: `/api/Accounts/${userId}/status`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param userId 
     * @returns UserAccount Success
     * @throws ApiError
     */
    public static async toggleAutoValidation(
userId: string,
): Promise<UserAccount> {
        const result = await __request({
            method: 'PUT',
            path: `/api/Accounts/${userId}/autovalidation`,
        });
        return result.body;
    }

    /**
     * @param userId 
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static async changeUsername(
userId: string,
requestBody?: ChangeUsernameRequest,
): Promise<any> {
        const result = await __request({
            method: 'PUT',
            path: `/api/Accounts/${userId}/username`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static async exportSelfData(
requestBody?: ExportUserDataRequest,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/api/Accounts/data/export`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static async destructivelyDeleteSelf(
requestBody?: DeleteUserAccountRequest,
): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/api/Accounts/data`,
            body: requestBody,
        });
        return result.body;
    }

}