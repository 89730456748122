
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-decorator";
import UserHistoryGame from "@/components/User/UserHistoryGame.vue";
import { GameStore } from "@/store/games";
import { Game, EventingService, Category, RunnableSegment, UserRunHistoryRollup, UserSegmentHistoryEntry, StaticContentService } from "@/api";
import OnAsync from "@/directives/OnAsync";
import { useToast } from "vue-toastification";
import RunGraph from "@/components/RunGraph.vue";
import TimeService from "@/services/TimeService";
import { ContentLoader } from "vue-content-loader";

@Options({
    components: {
        UserHistoryGame,
        RunGraph,
        ContentLoader
    },
    directives: {
        OnAsync
    }
})
export default class UserSegmentHistory extends Vue {
    history: UserRunHistoryRollup | null = null;
    games: Game[] = [];
    toast = useToast();

    @Prop()
    public userId: string | null = null;
    game: Game | undefined | null = null;
    category: Category | undefined | null = null;
    segment: RunnableSegment | undefined | null = null;
    runsByDifficulty: Record<string, UserSegmentHistoryEntry[]> = {};

    async created() {
        this.games = (await GameStore.get()).games;
        let gameId = this.$route.params.gameId;
        let catId = this.$route.params.categoryId;
        let segmentId = this.$route.params.segmentId;

        this.game = this.games.find(g => g.Id == gameId || g.UrlStub == gameId);
        if(this.game?.Categories == null || this.game?.RunnableSegments == null) return;

        this.category = this.game.Categories.find(c => c.Id == catId || c.UrlStub == catId);
        this.segment = this.game.RunnableSegments.find(g => g.Id == segmentId || g.UrlStub == segmentId);

        await this.load();
    }

    async load() {
        if(this.userId == null) return;
        try {
            this.history = await StaticContentService.userHistory(this.userId);

            if(this.history?.EntriesByCategory == null) return;

            this.runsByDifficulty = this.history.EntriesByCategory[this.category!.Name!]
                .filter(e => e.GameId == this.game?.Id && e.CategoryId == this.category?.Id && e.RunnableSegmentId == this.segment?.Id)
                .reduce((o,r) => {
                    o[r?.Difficulty!] = r.Runs ?? [];
                    return o;
                }, {} as Record<string,UserSegmentHistoryEntry[]>);
        } catch (e: any) {
            this.toast.error("Unable to load user history: " + e.toString());
        }
    }

    async rebuildHistory() {
        let rebuildEvent = {
            Event: "UserRunHistoryRollupUpdateEvent",
            Payload: {
                UserId: this.userId,
            },
        };

        await EventingService.dispatchEvent(JSON.stringify(rebuildEvent));
        await this.load();
    }

    public formatDate(datetime: string) {
        return TimeService.iso8601ToShortFormat(datetime);
    }
}
