
import { Options, Vue } from "vue-class-component";
import AuthStore from "@/store/auth";
import { AuthService, ResetPasswordRequest, ProfilesService, AuthType, StaticContentService } from "@/api";
import OnAsync from "@/directives/OnAsync";

export interface IResetRequest {
    UserId: string;
    Password: string | null;
    ConfirmPassword: string | null;
    ResetToken: string;
}

@Options({
    directives: {
        OnAsync
    }
})
export default class ResetPassword extends Vue {
    reset: ResetPasswordRequest | IResetRequest = {};
    errorMessage: string | null = null;

    async mounted() {
        if(this.$route.hash == "")
        {
            this.$router.push("/");
            return;
        }

        var parts = this.$route.hash.substr(1).split("/");

        if(parts.length != 2 || parts[0] == null || parts[1] == null)
        {
            this.$router.push("/");
            return;
        }

        this.reset.UserId = parts[0];
        this.reset.ResetToken = parts[1];
        this.$router.replace("/auth/reset");
    }

    async submit() {
        try {
            let resp = await AuthService.resetPassword(this.reset as ResetPasswordRequest);
            AuthStore.useToken(resp, AuthStore.refresh);

            this.errorMessage = null;
            this.reset.Password = null;
            this.reset.ConfirmPassword = null;

            this.$router.push("/");
        } catch {
            this.reset = <IResetRequest>{};
            this.errorMessage = "Reset failed. Each password reset link can only be used once. Try again, or request a new reset link.";
        }
    }
}
