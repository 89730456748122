export default class TimeService {

    private static shortFormatter = new Intl.DateTimeFormat(undefined, { year: "numeric", month: "short", day: "numeric" });

    public static stringToHms(input: string | undefined) {
        if(!input) return [0,0,0];

        let parts = input.split(":").filter(f => f.length);
        let hms = [];

        var current = 0;

        if(parts.length > 2) {
            let hours = parseInt(parts[current++]);

            if(isNaN(hours)) hours = 0;
            if(hours < 0) hours = 0;

            hms.push(hours);
        } else {
            hms.push(0);
        }

        if(parts.length > 1) {
            let minutes = parseInt(parts[current++]);

            if(isNaN(minutes)) minutes = 0;
            if(minutes < 0) minutes = 0;
            if(minutes > 59) minutes = 59;

            hms.push(minutes);
        } else {
            hms.push(0);
        }

        if(parts.length > 0) {
            let seconds = parseInt(parts[current++]);

            if(isNaN(seconds)) seconds = 0;
            if(seconds < 0) seconds = 0;
            if(seconds > 59) seconds = 59;

            hms.push(seconds);
        } else {
            hms.push(0);
        }

        return hms;
    }

    public static stringToSeconds(hmsString: string | undefined | number) { 
        if(typeof hmsString === "number")
            return hmsString;

        return TimeService.hmsToSeconds(TimeService.stringToHms(hmsString)); 
    }

    public static secondsToHms(seconds: number) { return [Math.floor(seconds / 3600), Math.floor((seconds % 3600) / 60), Math.floor(seconds % 60)] }
    public static secondsToString(seconds: number, minimal: boolean = false) { return TimeService.hmsToString(TimeService.secondsToHms(seconds), minimal) };
    

    public static hmsToSeconds(hms: number[]) { return (hms[0] * 60 * 60) + (hms[1] * 60) + hms[2]; }
    public static hmsToString(hms: number[], minimal: boolean = false) 
    { 
        let hours: string | null = null;
        let minutes = "00";
        let seconds = hms[2].toString().padStart(2, "0");

        if(minimal)
        {
            hours = hms[0] > 0 ? hms[0].toString() : null;
            minutes = hms[1].toString().padStart(2, "0");
        }
        else
        {
            hours = hms[0].toString().padStart(2, "0");
            minutes = hms[1].toString().padStart(2, "0");
        }

        return `${hours ?? ""}${hours == null ? '' : ':'}${minutes}:${seconds}` 
    };

    public static iso8601ToShortFormat(datetime: string) { return TimeService.shortFormatter.format(new Date(datetime))};
}