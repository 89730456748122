
import { Options, Vue } from "vue-class-component";
import { Prop, Watch } from "vue-decorator";
import ProfileInfo from "@/components/User/ProfileInfo.vue";
import {
  Claim,
    EventingService,
    Game,
    RunSubmissionsService,
    RunSubmissionStatus,
    UserCareer,
    UserProfile
} from "@/api";
import OnAsync from "@/directives/OnAsync";
import { GameStore } from "@/store/games";
import TimeService from "@/services/TimeService";
import UserProfileTabs from "@/components/User/UserProfileTabs.vue";
import UserCareerGame from "./UserCareerGame.vue";
import { ContentLoader } from "vue-content-loader";
import Masonry from "@/directives/Masonry";
import { useToast } from "vue-toastification";

interface ResolvedRunSubmission {
    Description: string;
    SubDescription: string;
    Duration?: number;
    Evidence?: string | null;
    SubmittedAt?: string;
    Status?: RunSubmissionStatus;
    Reason?: string | null;
}

@Options({
    components: {
        ProfileInfo,
        UserProfileTabs,
        UserCareerGame,
        ContentLoader
    },
    directives: {
        OnAsync,
        Masonry
    }
})
export default class UserSummary extends Vue {
    public recentSubmissions: ResolvedRunSubmission[] | null = null;
    public games: Game[] = [];

    chosenCategory: string | null = null;
    toast = useToast();
    levelSearch: string | null = null;
    canEditRuns = false;

    @Prop()
    public user: UserProfile | null = null;

    @Prop()
    public career: UserCareer | null = null;

    @Prop()
    public isOwnProfile: boolean = false;

    get catOrder() {
        return Object.keys(this.career?.RunsByCategory!).sort((a, b) => {  
            if (a == "Solo") return -1;
            if (b == "Solo") return 1;
            if (a == "Coop") return -1;
            if (b == "Coop") return 1;
            return 0;
        });
    }

    async created() {
        this.games = (await GameStore.get()).games;

        if (this.isOwnProfile) {
            await this.showRecentSubmissions();
        }
    }

    async mounted() {
        await this.load();
    }

    @Watch("career")
    public async watchCareer() {
        await this.load();
    }

    async load() {
        var hrClaims = this.$store.state.auth.hrClaimStrings;

        this.canEditRuns = (this.$store.state.auth.claims.userId == this.user?.UserId && hrClaims.indexOf(Claim.SELF_READ_WRITE) > 0)
            || hrClaims.indexOf(Claim.MODIFY_RUNS_GLOBAL) > 0
            || hrClaims.indexOf(Claim.MODIFY_RUNS_LIMITED) > 0;

        if(this.career?.RunsByCategory == null) return;

        this.chosenCategory = this.catOrder[0];
    }

    get totalPoints() {
        if(this.career?.RankInfoByCategory == null) return;

        return Object.values(this.career.RankInfoByCategory).reduce((a,r) => a + (r?.Points! || 0), 0)
    }

    get totalTrophies() {
        if(this.career?.RankInfoByCategory == null) return;

        return Object.values(this.career.RankInfoByCategory).reduce((a,r) => a + (r?.GoldCount! || 0) + (r?.SilverCount! || 0) + (r?.BronzeCount! || 0), 0)
    }

    get categoryGames() {
        return this.games.filter(g => g.Categories && g.Categories.filter(c => c.Name == this.chosenCategory).length > 0);
    }

    @Watch("isOwnProfile")
    async showRecentSubmissions() {
        if(this.isOwnProfile == false) {
            this.recentSubmissions = null;
            return;
        }

        let rawSubmissions = await RunSubmissionsService.getRecentSubmissions();
        if (!rawSubmissions || rawSubmissions.length == 0) {
            this.recentSubmissions = null;
        }

        let resolvedSubs = [] as ResolvedRunSubmission[];

        for (let sub of rawSubmissions) {
            var game = this.games.find((g) => g.Id == sub.GameId);
            if (!game?.Categories || !game.RunnableSegments) continue;
            var cat = game.Categories.find((c) => c.Id == sub.CategoryId);
            var seg = game.RunnableSegments.find((s) => s.Id == sub.RunnableSegmentId);
            if (!cat || !seg) continue;

            let desc = `${game.Name} ${sub.Difficulty}`;
            let subdesc = `${seg.Name} - ${cat.Name}`;

            resolvedSubs.push({
                Description: desc,
                SubDescription: subdesc,
                Duration: sub.Duration,
                Evidence: sub.Participants![0].EvidenceLink,
                SubmittedAt: TimeService.iso8601ToShortFormat(sub.SubmittedAt!),
                Status: sub.Status,
                Reason: sub.Reason,
            });
        }

        this.recentSubmissions = resolvedSubs;
    }
}
