
import { Options, Vue } from "vue-class-component";
import { Prop, Watch } from "vue-decorator";


@Options({
    components: {}
})
export default class IntegrationProfile extends Vue {

    @Prop() 
    public service: string = "unknown";

    @Prop()
    public unlink: (() => Promise<void>) | null = null;

    @Prop()
    public link: (() => Promise<void>) | null = null;

    @Prop()
    public profileName: string | null = null;

    @Prop()
    public profileUrl: string | null = null;

    @Prop()
    public avatarUrl: string | null = null;

    @Prop()
    public editable = false;

    @Prop()
    public hasValue = false;

    async mounted() {


    }

    async action() {
        this.hasValue ? await this.unlink!() : await this.link!();
    }

    get actionable() {
        return this.hasValue ? !!this.unlink : !!this.link;
    }

    get serviceIcon() {
        switch(this.service.toLowerCase())
        {
            case "xbox": return "/img/integrations/Xbox.svg";
            case "twitch": return "/img/integrations/TwitchGlitchPurple.svg";
            case "steam": return "/img/integrations/Steam.svg";
            case "youtube": return "/img/integrations/YouTube.svg";
            case "discord": return "/img/integrations/Discord.svg";
            default: return "/img/user.jpg"
        }
    }
}

