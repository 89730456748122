
import { Options, Vue } from "vue-class-component";
import { AuthService, ForgotPasswordRequest } from "@/api";
import OnAsync from '@/directives/OnAsync';

@Options({
    directives: {
        OnAsync
    }
})
export default class ForgotPassword extends Vue {
    resetRequest: ForgotPasswordRequest = {};
    errorMessage: string | null = null;
    resetInitiated: boolean = false;

    async submit() {
        try
        {
            let resp = await AuthService.forgotPassword(this.resetRequest);
            this.resetInitiated = true;
        }
        catch
        {
            this.resetInitiated = false;
            this.resetRequest = {};
        }
    }
}
