
import { Options, Vue } from 'vue-class-component';
import AuthStore from '@/store/auth';
import { AccountsService, AuthType, ChangeUsernameRequest } from '@/api';
import OnAsync from '@/directives/OnAsync';

@Options({
    directives: {
        OnAsync
    }
})
export default class ChangeUsername extends Vue 
{
    req: ChangeUsernameRequest = {};
    errorMessage: string | null = null;

    async mounted() {
        try { await AuthStore.pendingAuth; } catch {}
        
        if(!this.$store.state.auth.isAuthenticated) {
            this.$router.push("/");
            return;
        }
    }

    async submit() {
        try {
            this.req.UserId = this.$store.state.auth.claims.userId;
            await AccountsService.changeUsername(this.$store.state.auth.claims.userId, this.req);
            let loginResp = await AuthStore.login({
                AuthType: AuthType.PASSWORD,
                Username: this.req.NewUsername!,
                Secret: this.req.Password!,
            });

            this.errorMessage = null;
            this.req = {};

            if (loginResp) {
                this.$router.push("/");
            } else {
                this.$router.push("/auth/login");
            }
        } catch (e: any) {
            this.req = {};
            var start = e.body.indexOf(":")+1;
            this.errorMessage = "Username change failed: " + e.body.substr(start, e.body.indexOf("\n") - start).toString();
        }
    }
}
