
import { Options, Vue } from "vue-class-component";
import RunFeed from "@/components/Feeds/RunFeed.vue";
import StreamFeed from "@/components/Feeds/StreamFeed.vue";
import { RecordBoardKeySelectorConsts } from "@/components/Boards/RecordBoardKeySelectorConsts";
import { GameStore } from "@/store/games";
import { Game, LatestRecordsFeed, RecordBoardKey, StaticContentService } from "@/api";
import ExtensionRecordView from "@/components/Boards/ExtensionRecordView.vue";

@Options({
    components: {
        ExtensionRecordView,
        RunFeed,
        StreamFeed,
    },
})
export default class Extensions extends Vue {
    
    recordBoardKey: RecordBoardKey = { GameId: RecordBoardKeySelectorConsts.FeaturedKey, CategoryId: "Solo" };
    games: Game[] = [];
    gameId: string | undefined | null = null;
    recordsFeed: LatestRecordsFeed | null = null;

    async created() {
        this.games = (await GameStore.get()).games.filter(g => !g.Hidden);
        this.gameId = this.games[0].Id;

        let globals = await this.$store.state.globals;

        if(!globals.RecordsFeed) {
            this.recordsFeed = await StaticContentService.latestRecordsFeed();
        } else {
            this.recordsFeed = globals.RecordsFeed;
        }
    }

    get recordBoardTitle() {
        var filtered = this.games.filter(g => g.Id == this.gameId);
        if(filtered.length == 1) {
            return `${filtered[0].Name} Extra Category Records`
        } else {
            return "Game Records"
        }
    }
}
