/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ExtensionRecordBoard } from '../models/ExtensionRecordBoard';
import type { FeaturedRecordBoard } from '../models/FeaturedRecordBoard';
import type { GlobalMetadata } from '../models/GlobalMetadata';
import type { LatestRecordsFeed } from '../models/LatestRecordsFeed';
import type { LatestRunsFeed } from '../models/LatestRunsFeed';
import type { Leaderboard } from '../models/Leaderboard';
import type { LeaderboardHistory } from '../models/LeaderboardHistory';
import type { OldestRecordBoard } from '../models/OldestRecordBoard';
import type { RecentRecordBoard } from '../models/RecentRecordBoard';
import type { RecordBoard } from '../models/RecordBoard';
import type { StreamListFeed } from '../models/StreamListFeed';
import type { StreamListMetadata } from '../models/StreamListMetadata';
import type { UserCareer } from '../models/UserCareer';
import type { UserList } from '../models/UserList';
import type { UserProfile } from '../models/UserProfile';
import type { UserRunHistoryRollup } from '../models/UserRunHistoryRollup';
import { request as __request } from '../core/request';

export class StaticContentService {

    /**
     * @returns GlobalMetadata Success
     * @throws ApiError
     */
    public static async globalMetadata(): Promise<GlobalMetadata> {
        const result = await __request({
            method: 'GET',
            path: `/content/metadata/global.json`,
        });
        return result.body;
    }

    /**
     * @returns StreamListMetadata Success
     * @throws ApiError
     */
    public static async streamListMetadata(): Promise<StreamListMetadata> {
        const result = await __request({
            method: 'GET',
            path: `/content/metadata/streams.json`,
        });
        return result.body;
    }

    /**
     * @param gameId 
     * @param categoryId 
     * @param runnableSegmentId 
     * @param difficulty 
     * @returns Leaderboard Success
     * @throws ApiError
     */
    public static async leaderboard(
gameId: string,
categoryId: string,
runnableSegmentId: string,
difficulty: string,
): Promise<Leaderboard> {
        const result = await __request({
            method: 'GET',
            path: `/content/boards/${gameId}/${categoryId}/leaderboard/${runnableSegmentId}/${difficulty}.json`,
        });
        return result.body;
    }

    /**
     * @param gameId 
     * @param categoryId 
     * @param runnableSegmentId 
     * @param difficulty 
     * @returns LeaderboardHistory Success
     * @throws ApiError
     */
    public static async leaderboardHistory(
gameId: string,
categoryId: string,
runnableSegmentId: string,
difficulty: string,
): Promise<LeaderboardHistory> {
        const result = await __request({
            method: 'GET',
            path: `/content/history/${gameId}/${categoryId}/leaderboard/${runnableSegmentId}/${difficulty}.json`,
        });
        return result.body;
    }

    /**
     * @param gameId 
     * @param categoryId 
     * @returns RecordBoard Success
     * @throws ApiError
     */
    public static async recordBoard(
gameId: string,
categoryId: string,
): Promise<RecordBoard> {
        const result = await __request({
            method: 'GET',
            path: `/content/boards/${gameId}/${categoryId}/records.json`,
        });
        return result.body;
    }

    /**
     * @returns OldestRecordBoard Success
     * @throws ApiError
     */
    public static async oldestRecords(): Promise<OldestRecordBoard> {
        const result = await __request({
            method: 'GET',
            path: `/content/boards/oldest.json`,
        });
        return result.body;
    }

    /**
     * @returns FeaturedRecordBoard Success
     * @throws ApiError
     */
    public static async featuredRecordBoard(): Promise<FeaturedRecordBoard> {
        const result = await __request({
            method: 'GET',
            path: `/content/boards/featured.json`,
        });
        return result.body;
    }

    /**
     * @param gameId 
     * @returns ExtensionRecordBoard Success
     * @throws ApiError
     */
    public static async extensionRecordBoard(
gameId: string,
): Promise<ExtensionRecordBoard> {
        const result = await __request({
            method: 'GET',
            path: `/content/boards/extensions/${gameId}.json`,
        });
        return result.body;
    }

    /**
     * @returns RecentRecordBoard Success
     * @throws ApiError
     */
    public static async recentRecordBoard(): Promise<RecentRecordBoard> {
        const result = await __request({
            method: 'GET',
            path: `/content/boards/recent.json`,
        });
        return result.body;
    }

    /**
     * @returns LatestRunsFeed Success
     * @throws ApiError
     */
    public static async latestRunsFeed(): Promise<LatestRunsFeed> {
        const result = await __request({
            method: 'GET',
            path: `/content/feeds/latestRuns.json`,
        });
        return result.body;
    }

    /**
     * @returns LatestRecordsFeed Success
     * @throws ApiError
     */
    public static async latestRecordsFeed(): Promise<LatestRecordsFeed> {
        const result = await __request({
            method: 'GET',
            path: `/content/feeds/latestRecords.json`,
        });
        return result.body;
    }

    /**
     * @returns StreamListFeed Success
     * @throws ApiError
     */
    public static async streamList(): Promise<StreamListFeed> {
        const result = await __request({
            method: 'GET',
            path: `/content/feeds/streamList.json`,
        });
        return result.body;
    }

    /**
     * @param username 
     * @returns UserProfile Success
     * @throws ApiError
     */
    public static async userProfile(
username: string,
): Promise<UserProfile> {
        const result = await __request({
            method: 'GET',
            path: `/content/users/${username}/profile.json`,
        });
        return result.body;
    }

    /**
     * @param userId 
     * @returns UserCareer Success
     * @throws ApiError
     */
    public static async userCareer(
userId: string,
): Promise<UserCareer> {
        const result = await __request({
            method: 'GET',
            path: `/content/users/${userId}/career.json`,
        });
        return result.body;
    }

    /**
     * @param userId 
     * @returns UserRunHistoryRollup Success
     * @throws ApiError
     */
    public static async userHistory(
userId: string,
): Promise<UserRunHistoryRollup> {
        const result = await __request({
            method: 'GET',
            path: `/content/users/${userId}/history/rollup.json`,
        });
        return result.body;
    }

    /**
     * @returns UserList Success
     * @throws ApiError
     */
    public static async userListTop100(): Promise<UserList> {
        const result = await __request({
            method: 'GET',
            path: `/content/users/list-top100.json`,
        });
        return result.body;
    }

    /**
     * @returns UserList Success
     * @throws ApiError
     */
    public static async userList(): Promise<UserList> {
        const result = await __request({
            method: 'GET',
            path: `/content/users/list.json`,
        });
        return result.body;
    }

}