
import { Options, Vue, Prop, Watch } from "vue-decorator";
import TimeService from "@/services/TimeService";
import { LeaderboardEntry, LeaderboardHistory, Run } from "@/api";
import { ContentLoader } from "vue-content-loader";

@Options({
  components: {
    ContentLoader
  },
})
export default class RecordHistoryBoard extends Vue {
  @Prop()
  history: LeaderboardHistory | null = null;

  getHostname(url: string) {
    var rawHostname = new URL(url).hostname;

    return rawHostname.split(".").slice(-2).join(".");
  }

  formatDate(date: string): string {
    return TimeService.iso8601ToShortFormat(date);
  }
}
