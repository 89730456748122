
import { Options, Prop, Vue, Watch } from "vue-decorator";
import RunInputService, { RunInputContext } from "@/services/RunInputService";
import Typeahead from '@/components/Input/Typeahead.vue';
import { Category, Game, Run } from "@/api";
import SubmitBase, { SubmitHost } from "./SubmitBase";
import TimeInput from "../Input/TimeInput.vue";
import SubmitDetails from "@/components/Submit/SubmitDetails.vue";
import OnAsync from "@/directives/OnAsync";

@Options({
    components: {
        Typeahead,
        TimeInput,
        SubmitDetails
    },
    directives: {
        OnAsync
    }
})
export default class SubmitForm extends Vue implements SubmitHost {
    submitKind = "SimpleSubmitForm";

    @Prop()
    game: Game | null = null;

    @Prop()
    category: Category | null = null;

    @Prop()
    difficulty: string | null = null;

    @Prop()
    modelValue: any;
    
    globalContext: RunInputContext | null = null;

    base : SubmitBase = null!;

    public created() {
        this.base = new SubmitBase(this, this.$store, this.$router, this.$loading, this.$confirm, this.$emit, this.$toast, this.$filters);
    }

    public async mounted() {
        await this.base.mounted(this.submitKind);
        await this.initialize();
    }

    @Watch("game")
    @Watch("category")
    @Watch("difficulty")
    public async initialize() {
        await this.base.initialize();

        for(let sub of this.base.submissions) {
            sub.durationModifyHook = s => {
                this.updatePoints(s);
                this.anyChange();
            }
        }

        this.globalContext = this.base.createGlobalSubmissionContext();
    }

    public getPoints(context: RunInputContext, run: Run) {
        return RunInputService.getPoints(this.game!, this.base.recordData, context, run);
    }

    public async updatePoints(context: RunInputContext) {
        await RunInputService.updatePoints(this.game!, this.base.recordData, context, (await this.$store.state.globals).RunValidationOptions!);
    }

    public async onChange(c: RunInputContext) {
        this.anyChange();
        this.$emit("change", { run: c });
        await this.base.dirty(c);
    }

    public anyChange() {
        if(!this.globalContext) return;

        let anyRequiresEvidence = false;

        for(let submission of this.base.submissions.filter(s => s.duration != null && s.duration > 0)) {
            if(submission.requiresEvidence) {
                anyRequiresEvidence = true;
            }
        }

        this.globalContext.requiresEvidence = anyRequiresEvidence;
    }

    public async submit() {
        for(let submission of this.base.submissions) {

            if(this.globalContext?.partners) {
                submission.partners = this.globalContext.partners;
            }

            if(this.globalContext?.evidenceLinks) {
                submission.evidenceLinks = this.globalContext.evidenceLinks;
            }

            if(this.globalContext?.filterValues) {
                submission.filterValues = this.globalContext.filterValues;
            }
        }

        await this.base.submit();
    }
}
