
import { Options, Vue } from 'vue-class-component';
import RegisterForm from '@/components/Auth/Register.vue'

@Options({
  components: {
      RegisterForm
  },
})
export default class Register extends Vue 
{
    created() {
        if(this.$store.state.auth.isAuthenticated) {
            this.$router.push("/");
        }
    }
}
