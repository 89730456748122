
import { Options, Vue } from "vue-class-component";
import RecordBoardView, { RecordRow } from "@/components/Boards/RecordBoardView.vue";
import RunFeed from "@/components/Feeds/RunFeed.vue";
import StreamFeed from "@/components/Feeds/StreamFeed.vue";
import { RecordBoardKeySelectorConsts } from "@/components/Boards/RecordBoardKeySelectorConsts";
import { GameStore } from "@/store/games";
import {
    Category,
    FeaturedRecordBoard,
    FeaturedRecordBoardRow,
    Game,
    LatestRecordsFeed,
    LatestRunsFeed,
    RecordBoardKey,
    RecordBoardRow,
    StaticContentService,
} from "@/api";
import { Watch } from "vue-decorator";

@Options({
    components: {
        RecordBoardView,
        RunFeed,
        StreamFeed,
    },
})
export default class Home extends Vue {
    public recordBoardKey: RecordBoardKey = { GameId: RecordBoardKeySelectorConsts.FeaturedKey, CategoryId: "Solo" };
    games: Game[] = [];
    featuredBoard: FeaturedRecordBoard | null = null;
    boardData: RecordRow[] | null = null;
    runsFeed: LatestRunsFeed | null = null;
    recordsFeed: LatestRecordsFeed | null = null;
    categories: Category[] = [
        <Category>{
            Name: "Solo",
            UrlStub: "Solo",
            Featured: true,
            Id: "Solo",
        },
    ];

    async created() {
        this.games = (await GameStore.get()).games.filter((g) => !g.Hidden && (g.Categories?.filter(c => c.Featured)?.length ?? 0) > 0);
        let globals = await this.$store.state.globals;

        if (!globals?.FeaturedRecordBoard || !globals.RunsFeed || !globals.RecordsFeed) {
            await this.refresh();
        } else {
            this.featuredBoard = globals.FeaturedRecordBoard;
            this.updateCategories();
            this.boardData = this.transformBoardData(globals.FeaturedRecordBoard.EntriesByDesignation!["Solo"], p => p.GameId!);
            this.runsFeed = globals.RunsFeed;
            this.recordsFeed = globals.RecordsFeed;
        }
    }

    get recordBoardTitle() {
        if (this.recordBoardKey.GameId == RecordBoardKeySelectorConsts.FeaturedKey) {
            return "Full Game Records";
        } else {
            var filtered = this.games.filter((g) => g.Id == this.recordBoardKey.GameId);
            if (filtered.length == 1) {
                return `${filtered[0].Name} Records`;
            } else {
                return "Game Records";
            }
        }
    }

    oldGameValue?: string | null = null;
    gameChanged() {
        if (this.recordBoardKey.GameId != this.oldGameValue) {
            this.updateCategories();

            if (!this.categories.find((c) => c.Id == this.recordBoardKey.CategoryId)) {
                this.recordBoardKey.CategoryId = this.categories[0].Id;
                this.refreshRecordBoard();
            }
        }

        this.oldGameValue = this.recordBoardKey.GameId;
    }

    updateCategories() {
        if (this.recordBoardKey.GameId == RecordBoardKeySelectorConsts.FeaturedKey) {
            if (this.featuredBoard) {
                this.categories = Object.keys(this.featuredBoard.EntriesByDesignation!).map(
                    (name) =>
                        <Category>{
                            Name: name,
                            UrlStub: name,
                            Featured: true,
                            Id: name,
                        }
                );
            } else {
                this.categories = ["Solo", "Coop"].map(
                    (name) =>
                        <Category>{
                            Name: name,
                            UrlStub: name,
                            Featured: true,
                            Id: name,
                        }
                );
            }
        } else {
            this.categories =
                this.games.find((g) => g.Id == this.recordBoardKey.GameId)?.Categories?.filter((c) => c.Featured) ??
                [];
        }
    }

    async refresh() {
        this.recordsFeed = await StaticContentService.latestRecordsFeed();
        this.runsFeed = await StaticContentService.latestRunsFeed();

        await this.refreshRecordBoard();
    }

    async refreshRecordBoard() {
        if (this.recordBoardKey.GameId == RecordBoardKeySelectorConsts.FeaturedKey) {
            let board = await StaticContentService.featuredRecordBoard();
            this.boardData = this.transformBoardData(
                board.EntriesByDesignation![this.recordBoardKey.CategoryId ?? "Solo"]
            , p => p.GameId!);
        } else {
            let board = await StaticContentService.recordBoard(
                this.recordBoardKey.GameId!,
                this.recordBoardKey.CategoryId!
            );
            this.boardData = this.transformBoardData(board.Entries!, p => p.RunnableSegmentId!);
        }
    }

    private transformBoardData(boardData: (FeaturedRecordBoardRow | RecordBoardRow)[], idProp: (p: (FeaturedRecordBoardRow & RecordBoardRow)) => string): RecordRow[] {
        return (boardData ?? []).reduce((r: RecordRow[], e: FeaturedRecordBoardRow & RecordBoardRow) => {
            let name = e.GameName ?? e.Name ?? "Unspecified Game";

            if (e.CategoryName && e.CategoryName != this.recordBoardKey?.CategoryId) {
                name += " " + e.CategoryName;
            }

            for (const dif in e.RecordsByDifficulty) {
                let link = this.getLink(e, dif);
                r.push({
                    name: name,
                    difficulty: dif,
                    id: idProp(e),
                    records: e.RecordsByDifficulty[dif],
                    leaderboard: link,
                });
            }

            return r;
        }, <RecordRow[]>[]);
    }

    private getLink(entry: FeaturedRecordBoardRow & RecordBoardRow, difficulty: string) {
        let game = entry.GameId
            ? this.games.find((g) => g.Id == entry.GameId)
            : this.games.find((g) => g.Id == this.recordBoardKey.GameId);

        if (!game) return { name: "Leaderboards" };

        let params: any = {
            game: game.UrlStub,
            level: game.RunnableSegments?.find((r) => r.Id == entry.SegmentId || r.Name == entry.Name)?.UrlStub,
            difficulty: difficulty,
        };

        if (!game.Categories) {
            return { name: "Leaderboards", params };
        }

        let cat = game.Categories.find((c) => c.Id == entry.CategoryId);

        // fallback to looking for recordboardKey
        if(!cat) {
            cat = game.Categories.find(
                (c) =>
                    c.Name == this.recordBoardKey.CategoryId ||
                    c.Id == this.recordBoardKey.CategoryId
            );
        }

        if (!cat) {
            return { name: "Leaderboards", params };
        }

        params.category = cat.UrlStub;

        if (this.recordBoardKey.GameId == RecordBoardKeySelectorConsts.FeaturedKey || !game.RunnableSegments) {
            return { name: "Leaderboards", params };
        }

        return { name: "Leaderboards", params };
    }
}
