
import { Options, Vue } from "vue-class-component";
import AuthStore from "@/store/auth";
import { AuthService, RegisterConfirmRequest } from "@/api";
import OnAsync from "@/directives/OnAsync";

@Options({
    directives: {
        OnAsync
    }
})
export default class ConfirmRegistration extends Vue {
    confirmReq: RegisterConfirmRequest = {};

    async mounted() {
        if(this.$route.hash == "")
        {
            this.$router.push("/");
            return;
        }

        var parts = this.$route.hash.substr(1).split("/");

        if(parts.length != 2 || parts[0] == null || parts[1] == null)
        {
            this.$router.push("/");
            return;
        }

        this.confirmReq.UserId = parts[0];
        this.confirmReq.ConfirmationToken = parts[1];
        this.$router.replace("/auth/confirm");
        await this.submit();
    }

    async submit() {
        try {
            let resp = await AuthService.registerConfirm(this.confirmReq);
            AuthStore.useToken(resp, AuthStore.refresh);
            this.$toast.success("Registration verified! Logging you in...");
            this.$router.push("/");
        } catch {
            this.confirmReq = <RegisterConfirmRequest>{};
            this.$toast.error("Registration Verification Failed, please try again")
        }
    }
}
