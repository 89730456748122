
import { Options, Vue, Prop, Watch } from "vue-decorator";

@Options({})
export default class Typeahead extends Vue {
    @Prop()
    public modelValue: any;

    // Required, API to send queries to. Should be something like http://example.com/Entity/search?query=
    @Prop()
    public api: ((s: string) => Promise<any[]>) | string = "/search?query=";

    // Optional, minimum length to trigger API call
    @Prop()
    public minLength: number = 2;

    // Optional, transform the selected entry when assigning to modelValue
    @Prop()
    public modelTransform: (m: any) => any = m => m;

    // Optional, transform the provided value when binding initially
    @Prop()
    public bindTransform: (m: any) => any = m => m;

    // Optional, transform entries for display purposes
    @Prop()
    public displayTransform: (m: any) => any = m => m;

    @Prop()
    public placeholder: string | null = null;

    @Prop()
    public required: boolean = false;

    public queryInput: string | null = null;
    public showResults = false;
    public showLimitMessage = false;
    public searchResults: any[] | null = [];
    public loading = false;
    public value: any = null;
    public expandedStyle = {
        width: "auto"
    };

    public charLimit = 3;

    private timeout: number | undefined;

    public async mounted() {
        let xformed = this.bindTransform(this.modelValue);
        if(xformed != null) {
            let initResults = await this.query(xformed);
            if(initResults.length === 1) {
                this.select(initResults[0]);
            } else {
                this.queryInput = xformed;
                this.queryKeyUp(null);
            }
        }
    }

    public queryKeyUp(e: (KeyboardEventInit & Event) | null) {
        if(e != null) {
            this.updateExpandedStyle(e.target!);
        }

        if(this.queryInput == null) {
            this.loading = false;
            this.showResults = false;
            this.showLimitMessage = false;
            this.searchResults = [];
            return;
        }
        
        let trimmed = this.queryInput.trim();
        if(trimmed == null || trimmed.length < this.charLimit) {
            this.loading = false;
            this.showResults = false;
            this.showLimitMessage = true;
            this.searchResults = [];
            return;
        }

        this.loading = true;

        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            this.queryChanged(trimmed);
        }, 250);
    }
  
    public queryFocus(e: FocusEventInit & Event) {
        if(e != null) {
            this.updateExpandedStyle(e.target!);
        }

        let trimmed = this.queryInput?.trim();
        if(trimmed != null && trimmed.length >= this.charLimit) {
            this.showLimitMessage = false;
        } else {
            this.showLimitMessage = true;
        }
    }

    public async queryChanged(searchValue: string) {
        this.loading = true;

        this.searchResults = await this.query(searchValue);

        this.showResults = true;
        this.loading = false;
        this.showLimitMessage = false;
    }

    private async query(searchValue: string): Promise<any[]> {
        if(typeof this.api === 'string') {
            let response = await fetch(this.api + encodeURIComponent(searchValue));
            return await response.json();
        } else {
            return await this.api(searchValue);
        }
    }

    public select(value: any) {
        this.value = value;
        this.$emit("update:modelValue", this.modelTransform(value));
        this.showResults = false;
        this.showLimitMessage = false;
    }

    public clearSelected() {
        this.value = null;
        this.$emit("update:modelValue", null);

        // Focus on next tick so that v-if adds element to DOM
        setTimeout(() => {
            (this.$refs as any).typeaheadInput.focus();
            this.showResults = true;
        }, 0);
    }

    get displayValue() {
        return this.displayTransform(this.value);
    }

    public handleFocusOut(e: FocusEventInit & Event) {
        if(e.relatedTarget != null 
            && e.relatedTarget instanceof Node
            && e.currentTarget instanceof Node
            && e.currentTarget.contains(e.relatedTarget)) {
            return;
        }

        this.showResults = false;
        this.showLimitMessage = false;
    }

    private updateExpandedStyle(e: EventTarget) {
        let elem = e as HTMLElement;
        this.expandedStyle.width = elem.clientWidth + "px";
    }
}

