
import { Options, Vue } from 'vue-class-component';
import ForgotPassword from '@/components/Auth/ForgotPassword.vue'

@Options({
  components: {
      ForgotPassword
  },
})
export default class Login extends Vue 
{
    created() {
        if(this.$store.state.auth.isAuthenticated) {
            this.$router.push("/");
        }
    }
}
