
import { ExtensionRecordBoard, ExtensionRecordBoardRow, FeaturedRecordBoard, Game, RecordBoard, RecordBoardEntry, StaticContentService } from "@/api";
import { GameStore } from "@/store/games";
import { ContentLoader } from "vue-content-loader";
import { Options, Vue, Prop, Watch } from "vue-decorator";

interface RecordRow {
    row: ExtensionRecordBoardRow;
    name: string;
    difficulty: string;
    records: RecordBoardEntry[];
}

@Options({
    components: {
        ContentLoader
    }
})
export default class ExtensionRecordView extends Vue {
    @Prop()
    readonly gameId: string | null = null;

    boardData: RecordRow[] | null = null;
    games: Game[] = [];

    private dateFormatter = new Intl.DateTimeFormat(undefined, { year: "numeric", month: "short", day: "numeric" });

    async created() {
        await this.refresh();
        this.games = (await GameStore.get()).games.filter(g => !g.Hidden);
    }

    @Watch("gameId", {deep: true})
    onGameIdChanged(val: any, oldVal: any) {
      this.refresh();
    }

    async refresh() {
        let boardData : ExtensionRecordBoard | null = null;

        if(!this.gameId) return;

        boardData = await StaticContentService.extensionRecordBoard(this.gameId);

        this.boardData = boardData!.Entries!.reduce((r: RecordRow[], e) => {
            for (const dif in e.RecordsByDifficulty) {
                r.push({ name: e.CategoryName!, row: e, difficulty: dif, records: e.RecordsByDifficulty[dif] });
            }

            return r;
        }, <RecordRow[]>[]);
    }

    formatDate(date: string) : string {
      return this.dateFormatter.format(new Date(date));
    }

    getLink(entry: RecordRow) {
        let game = this.games.find(g => g.Id == this.gameId);

        if(!game) return { name: "Leaderboards" };

        let params: any = {
            game: game.UrlStub,
            level: game.RunnableSegments?.find(r => r.Featured)?.UrlStub,
            difficulty: entry.difficulty
        };

        if(!game.Categories) {
            return {name: "Leaderboards", params };
        }

        let cat = game.Categories.find(c => c.Id == entry.row.CategoryId);

        if(!cat) {
            return {name: "Leaderboards", params };
        }

        params.category = cat.UrlStub;

        return {name: "Leaderboards", params };
    }
}
