
import { Game } from "@/api";
import { GameStore } from "@/store/games";
import { Options, Vue } from "vue-class-component";
import Markdown from 'vue3-markdown-it';
import Collapsible from "@/directives/Collapsible";

@Options({
    components: {
        Markdown
    },
    directives: {
        Collapsible
    }
})
export default class Rules extends Vue {
    public games: Game[] = [];
    public navigatedToAnchor = false;

    // todo: move to metadata or something
    public siteRules = `
- Users may submit under only one account, and only one user may submit under an account.

- Must not use any skulls from the menu.
- Individual level runs must not start with weapons carried over from previous missions.
- Loading core saves and custom checkpoints at any point during a run is not allowed. This includes during opening cutscenes for individual levels. Loading a checkpoint with a preferred seed before a run however, is allowed.

- No macros are allowed. This includes controllers with a "turbo" functionality.
- Inputs may be remapped however, they must be one-to-one.
- Mouse and keyboard to controller adapters (such as XIM adapters) are not allowed.
- No emulator runs are allowed, Xbox 360/One/Series Backwards Compatibility emulator is excluded from this.

- Video submissions are required for all runs that are a top 5 placing or give at least 50% of max points.
- Runs outside the top 20 times, or runs that do not meet the above requirements do not require video proof however, it is strongly recommended.
- Twitch VOD submissions must be highlighted.
- Video submissions must be first person perspective and should, if possible, clearly and accurately show execution of the run.
- Co-op submissions that will give at least 50% of max points or a top 5 placing must include videos for all player viewpoints.
- Submitted gameplay must not be obscured by stream elements (webcam/livesplit/overlay). Health bars, ammo counters, reticle, radar, status messages (checkpoint... done), and MCC timer (if required for submission) must be clearly visible and unobstructed at all times.`

    async created() {
        this.games = (await GameStore.get()).games;
    }

    async updated() {
        if(window.location.hash && !this.navigatedToAnchor) {
            var elem = document.getElementById(window.location.hash.substr(1));
            if(elem) {
                elem.scrollIntoView();
                this.navigatedToAnchor = true;
            }
        }
    }
}

