import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-49a8cf45"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tabs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", null, [
      _createElementVNode("li", {
        class: _normalizeClass({'is-active': _ctx.$route.name == 'Profile'})
      }, [
        _createVNode(_component_router_link, { to: {name: 'Profile'} }, {
          default: _withCtx(() => [
            _createTextVNode("Profile")
          ]),
          _: 1
        })
      ], 2),
      _createElementVNode("li", {
        class: _normalizeClass({'is-active': _ctx.$route.name == 'History'})
      }, [
        _createVNode(_component_router_link, { to: {name: 'History'} }, {
          default: _withCtx(() => [
            _createTextVNode("History")
          ]),
          _: 1
        })
      ], 2),
      (_ctx.isOwnProfile)
        ? (_openBlock(), _createElementBlock("li", {
            key: 0,
            class: _normalizeClass({'is-active': _ctx.$route.name == 'Recent'})
          }, [
            _createVNode(_component_router_link, { to: {name: 'Recent'} }, {
              default: _withCtx(() => [
                _createTextVNode("Recent")
              ]),
              _: 1
            })
          ], 2))
        : _createCommentVNode("", true)
    ])
  ]))
}