
import { FeaturedRecordBoard, FeaturedRecordBoardRow, Game, RecordBoard, RecordBoardEntry, RecordBoardRow, StaticContentService } from "@/api";
import { GameStore } from "@/store/games";
import { Options, Vue, Prop, Watch } from "vue-decorator";
import { RecordBoardKeySelectorConsts } from "./RecordBoardKeySelectorConsts";
import { ContentLoader } from "vue-content-loader";

export interface RecordRow {
    name: string;
    difficulty: string;
    id: string;
    records: RecordBoardEntry[];
    leaderboard: any;
}

@Options({
    components: {
        ContentLoader
    }
})
export default class RecordBoardView extends Vue {
    @Prop()
    readonly recordBoardKey: any;

    @Prop()
    readonly rows: RecordRow[] | null = null;

    games: Game[] = [];

    private dateFormatter = new Intl.DateTimeFormat(undefined, { year: "numeric", month: "short", day: "numeric" });

    async created() {
        this.games = (await GameStore.get()).games;
    }

    formatDate(date: string) : string {
      return this.dateFormatter.format(new Date(date));
    }

    get groupedRows() {
        var grouped = this.rows?.reduce((a, r) => 
        {
            let found = a.filter(k => k.key == r.id)[0];
            if(!found) {
                found = {key: r.id, vals:[], bg: ""};
                a.push(found);
            }

            found.vals.push(r);

            return a;
        }, [] as {key: string, vals: RecordRow[], bg: string}[]);

        if(grouped == null || grouped == undefined)
            return [];

        for(var i = 0; i < grouped.length; i++) {
            if(i % 2 == 0) {
                grouped[i].bg = "rgba(255, 255, 255, 0)";
            } else {
                grouped[i].bg = "rgba(255, 255, 255, 0.05)";
            }
        }

        return grouped;
    }
}
