
import { Options, Vue, Prop, Watch } from "vue-decorator";
import TimeService from "@/services/TimeService";
import { LeaderboardEntry, Run } from "@/api";
import { ContentLoader } from "vue-content-loader";

@Options({
  components: {
    ContentLoader
  },
})
export default class RunBoard extends Vue {
  filteredEntries: LeaderboardEntry[] = [];

  @Prop()
  runs: LeaderboardEntry[] = [];

  @Prop()
  filters: {[name: string]: string | null} = {};

  hasPlatformFilter = true;
  extraFilters: Record<string, string | null> = {};

  getHostname(url: string) {
    var rawHostname = new URL(url).hostname;

    return rawHostname.split(".").slice(-2).join(".");
  }

  mounted() {
    this.updateFilters();
  }

  @Watch("runs")
  public watchRuns() {
    this.refresh();
  }

  @Watch("filters", { deep: true})
  public updateFilters() {
    this.refresh();
    if(this.filters) {
      // Special casing Platform filter for table layout
      this.hasPlatformFilter = this.filters.hasOwnProperty("Platform");
      this.extraFilters = Object.assign({}, this.filters);
      delete this.extraFilters["Platform"];
    }
  }

  async refresh() {
    this.applyFilters();
  }

  applyFilters() {
    if(this.runs == null) return;

    let entries = this.runs;

    for(let filter of Object.keys(this.filters)) {
      if(this.filters[filter] != null) {
        entries = entries.filter(f => f.FilterValues == null || f.FilterValues[filter] == this.filters[filter]);
      }
    }

    this.filteredEntries = entries;
  }

  formatDate(date: string): string {
    return TimeService.iso8601ToShortFormat(date);
  }
}
