
import { Category, Game, Run } from "@/api";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-decorator";
import UserHistoryGameLevel from "@/components/User/UserHistoryGameLevel.vue";

@Options({
    components: {
        UserHistoryGameLevel
    }
})
export default class UserHistoryGame extends Vue {
    @Prop()
    runs: Run[] = [];

    @Prop()
    game: Game | null = null;

    @Prop()
    categoryName: string | null = null;

    @Prop()
    submittedOnly: boolean = true;

    hasSubmitted(diff: string) {
        return this.runs.filter(r => r.GameId == this.game!.Id && r.CategoryId == this.category?.Id && r.Difficulty == diff).length > 0;
    }

    get category() {
        return this.game?.Categories?.find((c) => c.Name == this.categoryName);
    }

    get categorySegments() {
        var catSegs = this.category?.AllowedSegments!;
        return this.game?.RunnableSegments?.filter(s => catSegs.indexOf(s.Id!) >= 0);
    }
}
