import { Game, StaticContentService } from '@/api';
import store from "../store/index";

export class GameStore
{
    private static instance: Promise<GameStore>;

    private constructor() {}
    static get() : Promise<GameStore> {
        if(!GameStore.instance) {
            GameStore.instance = GameStore.create();
        }

        return GameStore.instance;
    }

    static refresh() : Promise<GameStore> {
        GameStore.instance = GameStore.create();
        return GameStore.instance;
    }

    private static async create(): Promise<GameStore> {
        // TODO: replace GameStore with central store usage?
        let gs = new GameStore()
        let metadata = await store.state.globals;
        gs.games = metadata?.Games ?? [];
        return gs;
    }

    public games: Game[] = [];

    public findGame(gameId: string) {
        return this.games.filter(g => g.Id === gameId)[0];
    }
}