
import { Options, Vue } from "vue-class-component";
import RunBoard from "@/components/Boards/RunBoard.vue";
import LeaderboardKeySelector from "@/components/Boards/LeaderboardKeySelector.vue";
import { GameStore } from "@/store/games";
import { Category, Game, Leaderboard, LeaderboardEntry, LeaderboardFilter, LeaderboardHistory, LeaderboardHistoryEntry, LeaderboardKey, StaticContentService } from "@/api";
import RunGraph from "@/components/RunGraph.vue";
import { RouteParamValueRaw } from "vue-router";
import { Watch } from "vue-decorator";
import Masonry from "@/directives/Masonry";

@Options({
    components: {
        LeaderboardKeySelector,
        RunBoard,
        RunGraph
    },
    directives: {
        Masonry
    }
})
export default class Leaderboards extends Vue {
    leaderboardKey: LeaderboardKey | null = null;
    games: Game[] = [];
    runs: Record<string, LeaderboardEntry[]> = {};
    availableFilters: LeaderboardFilter[] = [];
    filters: Record<string, string> = {};
    game: Game | undefined | null = null;
    history: Record<string, any[]> = {};
    category: Category | undefined | null = null; 

    async created() {
        this.games = (await GameStore.get()).games;

        let gameStub = this.getParam("game");
        let categoryStub = this.getParam("category");
        let levelStub = this.getParam("level");
        let difficulty = this.getParam("difficulty");

        let game = this.games.find(g => g.UrlStub == gameStub);
        if(game?.Categories && game.RunnableSegments && game.Difficulties) {
            let key = {} as LeaderboardKey;
            key.GameId = game.Id;
            
            let cat = game.Categories.find(c => c.UrlStub == categoryStub);
            if(cat) {
                key.CategoryId = cat.Id;

                let level = game.RunnableSegments.find(s => s.UrlStub == levelStub);
                if(level) {
                    key.RunnableSegmentId = level.Id;
                }

                if(difficulty) {
                    key.Difficulty = difficulty;
                }
            }

            this.leaderboardKey = key;
        } else {
            this.leaderboardKey = {};
        }
    }

    getParam(name: string) : string | null {
        let val = this.$route.params[name];
        if(typeof val == "string") {
            return val;
        } else if (Array.isArray(val)) {
            return val[0];
        } else { 
            return null;
        }
    }

    @Watch("leaderboardKey", { immediate: true, deep: true})
    async load() {
        let key = this.leaderboardKey;
        if(key?.GameId == null|| key.CategoryId == null || key.RunnableSegmentId == null) return;

        this.game = this.games.find(g => g.Id == key!.GameId);
        this.category = this.game?.Categories?.find(c => c.Id == key?.CategoryId);

        this.runs = {} as Record<string, LeaderboardEntry[]>;
        this.filters = {} as Record<string, string>;
        this.history = {};
        this.availableFilters = [];

        let difficulties = this.game?.Difficulties ?? [];

        let leaderBoardAndHistoryTasks = difficulties.flatMap(diff => {
            if(key?.GameId == null|| key.CategoryId == null || key.RunnableSegmentId == null) return [];
            let leaderboard = StaticContentService.leaderboard(key.GameId, key.CategoryId, key.RunnableSegmentId, diff).catch(h => null);
            let history = StaticContentService.leaderboardHistory(key.GameId, key.CategoryId, key.RunnableSegmentId, diff).catch(h => null);
            return [leaderboard, history];
        }).filter(t => t) as Promise<any>[];

        let completed = await Promise.all(leaderBoardAndHistoryTasks);

        let i = 0;
        for(let diff of difficulties) {
            let leaderboard = completed[i++] as Leaderboard | null;
            let history = completed[i++] as LeaderboardHistory | null;

            this.runs[diff] = leaderboard?.Entries ?? [];
            this.history[diff] = history?.Entries ?? [];

            for(let filter of leaderboard?.Filters ?? []) {
                if(filter.Values!.filter(v => v === null).length == 0) {
                    filter.Values!.unshift(null!);
                }
            }

            for(let filter of leaderboard?.Filters ?? []) {
                this.filters[filter.Name!] = filter.DefaultValue!;

                if(!this.availableFilters.find(f => f.Name == filter.Name))
                    this.availableFilters.push(filter);
            }
        }

        this.replaceRoute();
    }

    public replaceRoute() {
        if(!this.leaderboardKey) return;

        let game = this.games.find(g => g.Id == this.leaderboardKey!.GameId);

        if(!game?.Categories || !game?.RunnableSegments) return;

        let cat = game.Categories.find(c => c.Id == this.leaderboardKey?.CategoryId);
        let level = game.RunnableSegments.find(c => c.Id == this.leaderboardKey?.RunnableSegmentId);

        this.$router.replace({name: this.$route.matched[0].name, params: {
            game: game.UrlStub as RouteParamValueRaw,
            category: cat?.UrlStub as RouteParamValueRaw,
            level: level?.UrlStub as RouteParamValueRaw,
            difficulty: this.leaderboardKey.Difficulty as RouteParamValueRaw,
        }})
    }
}
