
import { Category, Game, Run, RunnableSegment } from "@/api";
import { GameStore } from "@/store/games";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-decorator";

@Options({})
export default class UserHistoryGameLevel extends Vue {
    @Prop()
    runs: any[] = [];

    @Prop()
    game: Game | null = null;

    @Prop()
    category: Category | null = null;

    @Prop()
    difficulty: string | null = null;

    @Prop()
    level: RunnableSegment | null = null;
    games: Record<string, Game> = {};

    async created() {
        let games = (await GameStore.get()).games;
        this.games = games.reduce((o,g) => { o[g.Id!] = g; return o; }, {} as Record<string, Game>);
    }

    get levelEntry() {
        return this.runs.filter((r) => r.RunnableSegmentId == this.level?.Id && r.Difficulty == this.difficulty);
    }

    public entryLink(entry: any) {
        let route = {name: "Level History", params: {gameId: entry.GameId, categoryId: entry.CategoryId, segmentId: entry.RunnableSegmentId}};

        let game = this.games[entry.GameId];

        if(game != null) {
            route.params.gameId = game.UrlStub;

            let cat = game.Categories?.find(c => c.Id == entry.CategoryId)?.UrlStub;
            if(cat != null) {
                route.params.categoryId = cat;
            }

            let seg = game.RunnableSegments?.find(c => c.Id == entry.RunnableSegmentId)?.UrlStub;
            if(seg != null) {
                route.params.segmentId = seg;
            }
        }

        return route;
    }
}
