
import CountryData, { Country } from "./../../assets/countries";
import { Options, Vue } from "vue-class-component";
import { Prop, Watch } from "vue-decorator";
import CountryFlag from "vue-country-flag-next";
import { AccountsService, AccountStatus, EventingService, IntegrationsService, ProfilesService, UserCareer, UserProfile } from "@/api";


@Options({
    components: {
        CountryFlag
    }
})
export default class CountryDesignationSelector extends Vue {
    editing = false;
    editable = true;
    savable = false;
    saving = false;
    saved = false;

    options: (Country | null)[] = [];
    nullOption = { country: "None", alpha2: null!, alpha3: null!, numeric: null! };
    chosen: Country = this.nullOption;


    @Prop()
    public modelValue: UserProfile | null = null;

    async mounted() {
        this.options = CountryData.countries.map(c => c);

        this.options.unshift(this.nullOption);

        this.init();
    }

    @Watch("modelValue")
    onModelValueChange(newVal: any, oldVal: any) {
        if(oldVal == newVal) return false;

        this.init();
    }

    init() {
        if(this.modelValue != null) {
            this.chosen = this.options.find(o => o?.alpha3 == this.modelValue?.CountryDesignation) || this.nullOption;
        } else {
            this.chosen = this.nullOption;
        }
    }

    public async transition() {
        if(this.editable) {
            this.editable = false;
            this.editing = true;
            this.savable = true;
        }
        else if (this.savable) {
            this.savable = false;
            this.saving = true;
            await this.save();
            this.saving = false;
            this.editing = false;
            this.saved = true;

            setTimeout(() => {
                this.saved = false;
                this.editable = true;
            }, 1000);
        }
    }

    public async save() {
        this.modelValue!.CountryDesignation = this.chosen.alpha3;
        await ProfilesService.setCountryDesignation(this.modelValue?.UserId!, this.modelValue!);
    }
}

