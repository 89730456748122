
import { Options, Vue } from "vue-class-component"
import Authenticating from "@/components/Authenticating.vue"
import { IntegrationsService } from "@/api";


@Options({
    components: {Authenticating}
})
export default class DiscordAuth extends Vue {
    async created() {
        if (this.$route.query.code?.toString() == null) {
            await this.$router.push({name: 'User Profile', params: {'username': this.$store.state.auth.username}});
        } else {
            await IntegrationsService.linkDiscord(this.$route.query.code?.toString());
            this.$toast.success("Linked your Discord account!");
            setTimeout(() => this.$router.push({name: 'User Profile', params: {'username': this.$store.state.auth.username}}), 1000);
        }
    }
}
