


import { GameStore } from "@/store/games";
import { Options, Vue, Prop, Watch } from "vue-decorator";
import Typeahead from '@/components/Input/Typeahead.vue';
import VideoLinkInput from "@/components/Integrations/VideoLinkInput.vue";
import { Category, Game, RunnableSegment, UserCareer, ProfilesService, RecordBoardEntry } from "@/api";
import UrlService from "@/services/UrlService";
import RunInputService, { RunInputContext, RunInputContextPartner } from "@/services/RunInputService";


@Options({
    components: {
        Typeahead,
        VideoLinkInput
    },
})
export default class RunInput extends Vue {
    @Prop()
    career: UserCareer | null = null;

    @Prop()
    recordData: { [runnableSegmentId: string]: { [difficulty: string]: RecordBoardEntry[] } } | null = null;

    @Prop()
    context: RunInputContext = <RunInputContext>{};

    games: Game[] = [];
    game: Game | null = null;
    category: Category | null = null;

    userTypeaheadApi = ProfilesService.findUsers;

    async mounted(){
        await this.initialize();
    }

    @Watch("context")
    async initialize() {
        this.games = (await GameStore.get()).games;
        this.game = this.games.find(g => g.Id == this.context?.gameId) ?? null;
        this.category = this.game?.Categories?.find(c => c.Id == this.context?.categoryId) ?? null;
    }

    public removePartner(partner: RunInputContextPartner) {
        RunInputService.removePartner(this.context, partner);
    }

    get categoryLevels(): {[props: string]: RunnableSegment} {
        if(this.category == null) return {};

        var initial = {} as {[props: string]: RunnableSegment};

        return this.game?.RunnableSegments!
            .filter(l => this.category!.AllowedSegments!.indexOf(l.Id!) >= 0)
            .reduce ((a: any, c) => { a[c.Id!] = c; return a; }, initial);
    }

    public validUrl(val: string | null) {
        return UrlService.isValidUrl(val);
    }

    public onChange() {
        this.$emit("change", { run: this.context });
    }
}


