import { Game, Leaderboard, RecordBoardEntry, Run, RunValidationOptions, StaticContentService } from "@/api";
import PointsService from "./PointsService";
import TimeService from "./TimeService";

export interface RunInputContextPartner {
    id: string;
    username: string;
}

export interface RunInputContext {
    gameId: string;
    categoryId: string;
    difficulty: string;
    runnableSegmentId: string | null;
    runnableSegmentName: string;
    isIl: boolean;
    allowedPartners: number;
    requiredPartners: number;
    duration: number | null;
    durationModifyHook: (c: RunInputContext) => void;
    editingDuration: boolean;
    pointsEstimate: number | null;
    rankEstimate: number | null;
    probablyRecord: boolean;
    probablyMistake: boolean;
    requiresEvidence: boolean;
    evidenceLinks: (string | null) [];
    partners: RunInputContextPartner[];
    filterValues: Record<string, string>;
    validation: any;
    pb: Run | null;
    leaderboard: Leaderboard | null
}

export default class RunInputService {

    public static async updatePoints(game: Game, recordData: Record<string,Record<string, RecordBoardEntry[]>> | null, context: RunInputContext | null, validationOpts: RunValidationOptions) {
        if(context == null) return;

        context.probablyRecord = false;
        context.requiresEvidence = false;
        context.probablyMistake = false;
        context.pointsEstimate = null;

        if(!context.duration || !recordData) { 
            return;
        }

        if(context.leaderboard == null) {
            context.leaderboard = await StaticContentService.leaderboard(context.gameId, context.categoryId, context.runnableSegmentId!, context.difficulty);
        }

        // If we have empty record data for the current segment, the there are no
        // record runs *yet* - and thus the current run is 'record'. 
        // Setting record seconds to one more than submission for convenience
        let record = context.duration + 1;

        if(recordData[context.runnableSegmentId!] && recordData[context.runnableSegmentId!][context.difficulty] && recordData[context.runnableSegmentId!][context.difficulty][0]) {
            var recordString = recordData[context.runnableSegmentId!][context.difficulty][0].Duration;
            record = TimeService.stringToSeconds(recordString);
        }

        if(context.duration <= record) {
            context.probablyRecord = true;
        }

        if(PointsService.calculateRawPoints(context.duration, record) < 0.5) {
            context.probablyMistake = true;
        }

        context.requiresEvidence = RunInputService.requiresEvidence(game, context, validationOpts);
    }

    private static requiresEvidence(game: Game, context: RunInputContext, validationOptions: RunValidationOptions) : boolean
    {
        if(validationOptions == null) {
            validationOptions = {
                RanksOverHaveOptionalEvidence: 20,
                RanksUnderOrEqualToRequireEvidence: 5,
                EvidenceThresholdPercent: 50
            }
        }

        const segment = game!.RunnableSegments!.find(s => s.Id == context!.runnableSegmentId);
        if(!segment?.Points) return true;
        let maxPoints = segment.Points;
        var cat = game.Categories?.find(c => c.Id == context!.categoryId);
        if(cat?.PointsMultiplier !== undefined && cat?.PointsMultiplier !== null)
        {
            maxPoints *= cat.PointsMultiplier;
        }

        const leaderboard = context.leaderboard;
        if(leaderboard?.Entries == null || leaderboard?.Entries?.length == 0) {
            context.pointsEstimate = maxPoints;
            return true;
        }

        context.pointsEstimate = PointsService.calculatePoints(leaderboard.Entries[0].Duration!, context.duration!, maxPoints);

        var rank = 0;
        while(rank < leaderboard.Entries.length
            && leaderboard.Entries[rank].Duration! < context.duration!) 
        {
            rank++;
        }

        // ranks start at 1
        rank++;

        context.rankEstimate = rank;

        if (rank <= (validationOptions.RanksUnderOrEqualToRequireEvidence ?? 5))
            return true;

        if (rank > (validationOptions.RanksOverHaveOptionalEvidence ?? 20))
            return false;

        var pointThreshold = maxPoints * ((validationOptions.EvidenceThresholdPercent || 100) / 100);

        return context.pointsEstimate >= pointThreshold;
    }

    public static getPoints(game: Game, recordData: Record<string,Record<string, RecordBoardEntry[]>> | null, context: RunInputContext | null, run: Run) {
        if(context == null
            || recordData == null 
            || recordData[context.runnableSegmentId!] == null 
            || recordData[context.runnableSegmentId!][context.difficulty] == null
            || recordData[context.runnableSegmentId!][context.difficulty].length == 0
            || game == null) return 0;

        var recordString = recordData[context.runnableSegmentId!][context.difficulty][0].Duration;
        let record = TimeService.stringToSeconds(recordString);
        const segment = game.RunnableSegments!.filter(s => s.Id == context!.runnableSegmentId)[0];

        if(segment?.Points == null) return 0;

        let maxPoints = segment.Points;

        var cat = game.Categories?.find(c => c.Id == context.categoryId);
        if(cat?.PointsMultiplier !== undefined && cat?.PointsMultiplier !== null)
        {
            maxPoints *= cat.PointsMultiplier;
        }

        return PointsService.calculatePoints(record, TimeService.stringToSeconds(run.Duration), maxPoints)
    }

    public static removePartner(context: RunInputContext | null, partner: RunInputContextPartner) {
        if(context == null) return;

        var index = context.partners.indexOf(partner);
        context.partners.splice(index, 1);
        context.evidenceLinks.splice(index+1, 1);
    }
}