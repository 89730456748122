
import { GameStore } from "@/store/games";
import { Category, Game } from "@/api";
import { Options, Vue, Prop, Watch } from "vue-decorator";

export interface SubmitSelection {
    game: Game | null;
    category: Category | null;
    difficulty: string | null;
}

@Options({})
export default class SubmitSelector extends Vue {
    games: Game[] = [];

    @Prop()
    modelValue: SubmitSelection = {} as SubmitSelection;

    async created() {
        await this.load();
    }

    async load() {
        var gamestore = await GameStore.get();
        this.games = gamestore.games.filter(g => !g.Hidden);
        this.modelValue.game = this.modelValue.game || this.games[0];
        this.modelValue.category = this.modelValue.category || this.modelValue.game.Categories![0];
        this.modelValue.difficulty = this.modelValue.difficulty || this.modelValue.category.Difficulties![0];
    }

    public gameChange() {
        if(this.modelValue.game == null) return;
        this.modelValue.category = this.modelValue.game.Categories![0];
        this.$emit("gameChange", this.modelValue.game);
        this.categoryChange();
    }

    public categoryChange() {
        if(this.modelValue.category == null) return;
        this.modelValue.difficulty = this.modelValue.category.Difficulties![0];
        this.$emit("categoryChange", this.modelValue.category);
        this.difficultyChange();
    }

    public difficultyChange() {
        this.$emit("difficultyChange", this.modelValue.difficulty);
        this.anyChange();
    }

    public anyChange() {
        this.$emit("update:modelValue", {
            game: this.modelValue?.game,
            category: this.modelValue?.category,
            difficulty: this.modelValue?.difficulty
        });
    }

    get normalCats() {
        return this.modelValue?.game?.Categories?.filter(c => !c.IsExtension);
    }

    get extensionCats() {
        return this.modelValue?.game?.Categories?.filter(c => c.IsExtension);
    }
}

