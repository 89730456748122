
import { Options, Vue } from "vue-decorator";
import { Claim, ProfilesService, StaticContentService, StreamListEntry, StreamListFeed } from "@/api";
import AuthStore from "@/store/auth";


@Options({})
export default class StreamFeed extends Vue {
    private static shouldUseInitialLoad = true;

    private refreshInterval : number | undefined = undefined;
    public feed: StreamListFeed | null = null;
    public canModify: boolean = false;
    public skippedRefresh: boolean = false;

    //Make uptime readable
    uptime(timestamp: string) {
        var time = new Date(Date.now() - new Date(timestamp).getTime()).toISOString();
        var hours = parseInt(time.substr(11,2));
        var minutes = parseInt(time.substr(14,2));
        var seconds = parseInt(time.substr(17,2));

        var timecode = "Streaming for " + seconds + " second";

        if (seconds > 1) {
            timecode = "Streaming for " + seconds + " seconds";
        }

        if (minutes > 0) {
            timecode = "Streaming for " + minutes + " minute";
            if (minutes > 1) {
                timecode = timecode + "s";
            }
        }

        if (hours > 0) {
            timecode = "Streaming for " + hours + " hour";
            if (hours > 1) {
                timecode = timecode + "s";
            }
            
            if (minutes > 0) {
                timecode = timecode + " and " + minutes + " minute";
                if (minutes > 1) {
                    timecode = timecode + "s";
                }
            }
        }

        return timecode;
    }

    async created() {
        this.refresh();
    }

    async mounted() {
        this.refreshInterval = setInterval(() => this.refresh(), 1000 * 60);
        try { await AuthStore.pendingAuth; } catch {}
        this.canModify = AuthStore.hasClaim(Claim.MODIFY_STREAM_LIST);

        document.addEventListener("visibilitychange", this.handleVisibilityChange, false);
    }

    beforeUnmount() {
        clearInterval(this.refreshInterval);
        document.removeEventListener("visibilitychange", this.handleVisibilityChange);
    }

    async handleVisibilityChange() {
        if (document.visibilityState !== "hidden" && this.skippedRefresh) {
            this.refresh();
        }
    }

    async refresh() {
        if(StreamFeed.shouldUseInitialLoad) {
            StreamFeed.shouldUseInitialLoad = false;
            let globals = await this.$store.state.globals;
            if(globals?.StreamFeed?.Entries) {
                this.feed = globals.StreamFeed;
                this.feed.Entries = globals.StreamFeed.Entries.sort((a,b) => (b.Viewers || 0) - (a.Viewers || 0));
                return;
            }
        }

        if (document.visibilityState === "hidden") {
            // We'll skip pulling new info if the tab isn't active, and signal that we've done so for when we come back
            this.skippedRefresh = true;
            return;
        }

        this.skippedRefresh = false;

        let feed = await StaticContentService.streamList();

        if(feed.Entries)
        {
            feed.Entries = feed.Entries.sort((a,b) => (b.Viewers || 0) - (a.Viewers || 0));
        }

        this.feed = feed;
    }

    public async removeFromList(entry: StreamListEntry) {
        await ProfilesService.toggleStreamVisibility(entry.UserId!);

        (entry as any).toggled = true;
    }
}
