
import { Options, Vue } from "vue-class-component";
import { Prop, Watch } from "vue-decorator";
import UserHistoryGame from "@/components/User/UserHistoryGame.vue";
import { GameStore } from "@/store/games";
import { Game, EventingService, Claim, StaticContentService, UserProfile, UserCareer } from "@/api";
import OnAsync from "@/directives/OnAsync";
import { useToast } from "vue-toastification";
import UserProfileTabs from "@/components/User/UserProfileTabs.vue";
import { ContentLoader } from "vue-content-loader";
import Masonry from "@/directives/Masonry";

@Options({
    components: {
        UserHistoryGame,
        UserProfileTabs,
        ContentLoader
    },
    directives: {
        OnAsync,
        Masonry
    }
})
export default class UserHistory extends Vue {
    history: any = null;
    chosenCategory: string | null = null;
    games: Game[] = [];
    toast = useToast();
    levelSearch: string | null = null;

    @Prop()
    public user: UserProfile | null = null;

    @Prop()
    public career: UserCareer | null = null;

    @Prop()
    public isOwnProfile: boolean = false;

    async mounted() {
        await this.load();
        this.games = (await GameStore.get()).games.filter(g => !g.Hidden);
    }

    @Watch("user")
    @Watch("isOwnProfile")
    async load() {
        if(this.user?.UserId == null) return;
        try {
            this.history = await StaticContentService.userHistory(this.user.UserId);
            let cats = Object.keys(this.history.EntriesByCategory)
                .filter(c => this.history.EntriesByCategory[c].length > 0);
            let solo = cats.findIndex(c => c == "Solo");

            this.chosenCategory = solo >= 0 ? "Solo" : cats[0];
        } catch (e: any) {
            this.toast.error("Unable to load user history: " + e.toString());
        }
    }

    async rebuildHistory() {
        let rebuildEvent = {
            Event: "UserRunHistoryRollupUpdateEvent",
            Payload: {
                UserId: this.user?.UserId,
            },
        };

        await EventingService.dispatchEvent(JSON.stringify(rebuildEvent));
        await this.load();
    }

    get categoryGames() {
        return this.games.filter(g => g.Categories && g.Categories.filter(c => c.Name == this.chosenCategory).length > 0);
    }
}
