
import { Vue } from "vue-class-component";
import { Prop } from "vue-decorator";


export default class UserProfileTabs extends Vue
{
    
    @Prop()
    public isOwnProfile: boolean = false;
}

