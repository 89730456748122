

import { Options, Vue } from "vue-decorator";
import { LoadingPlugin } from '@/plugins/Loading';


@Options({})
export default class Loading extends Vue {
    show = false;

    get animationDuration() {
        return this.$store.state.windowWidth / 450;
    }

    created() {
        LoadingPlugin.loadingAction = this.prompt;
    }

    public prompt(show: boolean) {
        this.show = show;
    }
}

