
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';
import NavBar from '@/components/NavBar.vue';
import Confirm from '@/plugins/Confirm.vue';
import Prompt from '@/plugins/Prompt.vue';
import Configuration from './config';
import { useToast } from "vue-toastification";
import Loading from './plugins/Loading.vue';

@Options({
  components: {
    NavBar,
    Confirm,
    Prompt,
    Loading
  },
  computed: mapState(["auth"])
})
export default class App extends Vue 
{
  getHash = process.env.VUE_APP_GIT_HASH;
  toast = useToast();

  beforeMount() {
    // Ensure configuration is initialized ASAP
    Configuration.get();
  }

  mounted () {
    this.onResize()
    window.addEventListener('resize', this.onResize, { passive: true })
  }

  public versionClick(e: MouseEvent) {
    if(e.detail === 3) {
      this.$store.commit("enableDevMode");
      this.toast.info("Enabled dev mode");
    }
  }

  onResize () {
    this.$store.commit("setMobile", window.innerWidth < 768); // same width as Bulma's CSS break point
    this.$store.commit("setMobileSmall", window.innerWidth < 568); // Arbitrary small width
    this.$store.commit("setWindowWidth", window.innerWidth);
  }

  beforeUnmount () {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize, { passive: true } as EventListenerOptions)
    }
  }
}
