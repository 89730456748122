
import { RunFeedEntry } from "@/api";
import { Options, Vue, Prop } from "vue-decorator";

@Options({})
export default class RunFeed extends Vue {
    @Prop()
    public feed:  RunFeedEntry[] | undefined | null = null;

    public leaderboardLink(entry: RunFeedEntry) {
        return entry.LeaderboardUrl!.replace(window.location.protocol + '//' + window.location.host, '');
    }
}
