
import { Options, Prop, Vue, Watch } from "vue-decorator";
import RunInput from "@/components/Input/RunInput.vue";
import Typeahead from '@/components/Input/Typeahead.vue';
import { Category, Game } from "@/api";
import SubmitBase, { SubmitHost } from "./SubmitBase";

@Options({
    components: {
        RunInput,
        Typeahead
    },
})
export default class Submit extends Vue implements SubmitHost {
    submitKind = "FullSubmitForm";

    @Prop()
    game: Game | null = null;

    @Prop()
    category: Category | null = null;

    @Prop()
    difficulty: string | null = null;

    base : SubmitBase = null!;

    public created() {
        this.base = new SubmitBase(this, this.$store, this.$router, this.$loading, this.$confirm, this.$emit, this.$toast, this.$filters);
    }

    public async mounted() {
        await this.base.mounted(this.submitKind);
    }

    @Watch("game")
    @Watch("category")
    @Watch("difficulty")
    public async initialize() {
        await this.base.initialize();
    }
}
