
import { Options, Vue } from "vue-class-component";
import LeaderboardKeySelector from "@/components/Boards/LeaderboardKeySelector.vue";
import { Category, Game, LeaderboardKey, OldestRecordBoard, OldestRecordBoardEntry, RunParticipantInfo, StaticContentService } from "@/api"
import TimeService from "@/services/TimeService";
import { GameStore } from "@/store/games";
import { ContentLoader } from "vue-content-loader";

interface OldestRecordEntry {
    rank: number;
    occurredAt: string | undefined;
    segmentName: string | undefined;
    categoryName: string | undefined;
    gameName: string | undefined;
    difficulty: string | undefined;
    duration: number | undefined;
    participants: RunParticipantInfo[];
    ageDays: number | undefined;
}

interface Filters {
    GameId: string | null;
    CategoryName: string | null;
}

@Options({
    components: {
        LeaderboardKeySelector,
        ContentLoader
    }
})
export default class OldestRecords extends Vue {
    filters: Filters = { GameId: null, CategoryName: null };
    records: OldestRecordBoard | null = null;
    filteredRecords: OldestRecordEntry[] | null = null;
    games: (Game|null)[] = [];
    categories: (string|null)[] = [];
    categoryLookup: Record<string, string> = {};
    gameLookup: Record<string, [Game, Record<string, Category>]> | null = null;

    async created() {
        this.records = await StaticContentService.oldestRecords();
        let games = (await GameStore.get()).games.filter(g => !g.Hidden);

        this.gameLookup = games.reduce((a,g) => {
            let catLookup = g.Categories!.reduce((ca, c) => {
                ca[c.Id!] = c;
                return ca;
            }, {} as Record<string, Category>);

            a[g.Id!] = [g, catLookup]
            return a;
        }, {} as Record<string, [Game, Record<string, Category>]>);

        this.games = games;
        this.categories = [...new Set(games.flatMap(g => g.Categories).map(c => c!.Name))].filter(c => c) as string[];
        this.categoryLookup = games.flatMap(g => g.Categories).reduce((p,v) => 
        {
            if(v?.Id) {
                p[v!.Id!] = v.Name!;
            }

            return p;
        }, {} as Record<string, string>);

        this.filter();
    }

    public filter() {
        if(!this.records?.Entries) {
            this.filteredRecords = [];
            return;
        }

        if(!this.filters) {
            this.filteredRecords = this.records.Entries.map(this.mapEntry);
            return;
        }

        let mapped = this.records.Entries.map((r,i) => {
            if(!this.filters) return null;

            var match = true;

            if(match && this.filters.GameId && r.GameId != this.filters.GameId) {
                match = false;
            }

            if(match && this.filters.CategoryName && this.categoryLookup[r.CategoryId!] != this.filters.CategoryName) {
                match = false;
            }

            return match ? this.mapEntry(r, i) : null;
        });

        this.filteredRecords = mapped.filter(m => m) as OldestRecordEntry[];
    }

    mapEntry(entry: OldestRecordBoardEntry, index: number) : OldestRecordEntry {
        return {
            rank: index+1,
            occurredAt: this.formatDate(entry.OccurredAt!),
            segmentName: this.segmentName(entry),
            categoryName: this.category(entry),
            gameName: this.game(entry),
            difficulty: entry.Difficulty,
            duration: entry.Duration,
            participants: entry.Participants!,
            ageDays: this.age(entry.OccurredAt!)
        }
    }

    formatDate(date: string) {
        return TimeService.iso8601ToShortFormat(date);
    }

    age(date: string | undefined) {
        if(!date) return 0;
        return Math.round((new Date().getTime()-new Date(date).getTime())/(1000*60*60*24));
    }

    game(record: OldestRecordBoardEntry) {
        if(this.gameLookup == null || record?.GameId == null) return;

        return this.gameLookup[record.GameId][0]?.Name;
    }

    category(record: OldestRecordBoardEntry) {
        if(this.gameLookup == null || record?.GameId == null) return;

        return this.gameLookup[record.GameId][1][record.CategoryId!]?.Name;
    }

    segmentName(record: OldestRecordBoardEntry) {
        if(this.gameLookup == null || record?.GameId == null) return;

        return this.gameLookup[record.GameId][0]?.RunnableSegments?.find(s => s.Id == record.RunnableSegmentId)?.Name;
    }
}

