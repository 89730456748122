

import { Options, Vue, Prop, Watch } from "vue-decorator";
import PromptArguments, { PromptPlugin } from '@/plugins/Prompt';


@Options({})
export default class Prompt extends Vue {
    private static DefaultArgs: PromptArguments = {
        message: "Are you sure?",
        confirmAlias: "Confirm",
        cancelAlias: "Cancel",
        entryType: "text"
    };

    showModal = false;
    promptValue: string | null = null;
    args = Prompt.DefaultArgs;
    messages: string[] = [];
    pendingConfirm: Promise<[boolean, string|null]> = Promise.resolve<[boolean, string|null]>([true, null]);

    resolver: ((v: [boolean, string|null]) => void) | null = null;

    created() {
        PromptPlugin.prompt = this.prompt;
    }

    public prompt(args: PromptArguments | null): Promise<[boolean, string|null]> {
        this.pendingConfirm = new Promise<[boolean, string|null]>((resolve, reject) => {
            this.resolver = resolve;
        });
        
        this.args = Object.assign({}, Prompt.DefaultArgs, args);

        if(args?.message) {
            this.messages = args?.message.split("\n");
        } else {
            this.messages = [args?.message!];
        }

        this.showModal = true;
        return this.pendingConfirm;
    }

    public cancel() {
        if(this.resolver)
            this.resolver([false, this.promptValue]);

        this.showModal = false;
        this.promptValue = null;
    }

    public confirm() {
        if(this.resolver)
            this.resolver([true, this.promptValue]);

        this.showModal = false;
        this.promptValue = null;
    }

    public overlayCancel(e: InputEvent) {
        if((e.target as HTMLElement).classList.contains("overlay")) {
            this.cancel();
        }
    }
}

