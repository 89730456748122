
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex'
import AuthStore from '@/store/auth'
import { RunSubmissionsService, UserProfile } from "@/api";
import OnAsync from '@/directives/OnAsync';
import FocusToggle from '@/directives/FocusToggle';

@Options({
  components: {
    NavBar
  },
  directives: {
      FocusToggle,
      OnAsync
  },
  computed: mapState(["auth", "attentionRequired"])
})
export default class NavBar extends Vue 
{
    public showAdvanced = false;
    public user: UserProfile | null | undefined;
  
    async mounted() {
        this.$store.subscribe((m,s) => 
        {
            if(m.type == "authenticate" && AuthStore.hasAdminClaim()) {
                RunSubmissionsService.getSubmissions().then(s => 
                {
                    if((s.Submissions?.length ?? 0) > 0) {
                        this.$store.commit("attentionRequired", "/admin/queue");
                    }
                });
            }
        })
    }

    async logout() {
        await AuthStore.logout();
        this.$router.push("/");
    }

    async toggleMenu() {
        this.$store.commit("toggleNav");
    }

    public menuPress(e: MouseEventInit) {
        if(e.ctrlKey) {
            this.showAdvanced = true;
        }
    }

    async clearCaches() {
        this.$store.dispatch("clearCaches");
    }

    get hasAdminClaim() {
        return AuthStore.hasAdminClaim();
    }
}
