
import { Options, Vue, Prop, Watch } from "vue-decorator";
import TimeService from "@/services/TimeService";

// I hate this and have spent too much time fighting to make this sane
// it still needs work, but I can't gather enough fucks to give
@Options({})
export default class TimeInput extends Vue
{
    private static Separator = ":";

    @Prop()
    public modelValue: string | number | null = null;
    private modelAsString = false;

    public totalSeconds: number | null = 0;
    public rawValue: string = "";
    public validationClass = "";

    private readonly validKeys = [
        "Digit0","Digit1","Digit2","Digit3","Digit4","Digit5","Digit6","Digit7","Digit8","Digit9",
        "Numpad0","Numpad1","Numpad2","Numpad3","Numpad4","Numpad5","Numpad6","Numpad7","Numpad8","Numpad9",
        "Backspace","Semicolon","Tab","ArrowLeft","ArrowRight","Delete","Home","End"
    ];

    private readonly validationKeys = ["Enter", "NumpadEnter", "Escape"];

    private readonly validSeparators = [
        "Semicolon","NumpadSubtract","NumpadAdd","Space","NumpadMultiply","Slash","Comma","Backslash","Minus","Equal","NumpadDivide"
    ];

    mounted() {
        this.init();
    }

    @Watch("modelValue")
    onModelValueChange(newVal: any, oldVal: any) {
        if(oldVal == newVal) return false;
        if(newVal == this.totalSeconds) return false;

        this.init();
    }

    init() {
        if(typeof this.modelValue === 'string' || <any>this.modelValue instanceof String) {
            this.modelAsString = true;
            this.totalSeconds = TimeService.stringToSeconds(<string>this.modelValue);
        } else if(typeof this.modelValue === 'number' || <any>this.modelValue instanceof Number) {
            this.modelAsString = false;
            this.totalSeconds = <number>this.modelValue;
        } else {
            this.modelAsString = false;
            this.totalSeconds = null;
        }

        if(this.totalSeconds != null) {
            this.rawValue = TimeService.secondsToString(this.totalSeconds);
        } else {
            this.rawValue = "";
        }
    }

    public update() {
        if(this.totalSeconds == null) {
            if(this.modelAsString) {
                this.$emit("update:modelValue", "");
            } else {
                this.$emit("update:modelValue", null);
            }
        } else {
            if(this.modelAsString) {
                this.$emit("update:modelValue", TimeService.secondsToString(this.totalSeconds));
            } else {
                this.$emit("update:modelValue", this.totalSeconds);
            }
        }

        this.$emit("update");
    }

    public handleInput(event: Event) {
        if(this.rawValue == "") {
            this.totalSeconds = null;
            this.update();
            return;
        }

        let textOffset: number | null = null;
        let element : HTMLInputElement | null = null;

        if(event.target instanceof HTMLInputElement) {
            element = event.target;
            textOffset = element.selectionStart;
        }

        // Handle backspacing a separator in the middle
        if(textOffset != null && event instanceof InputEvent && event.inputType == "deleteContentBackward") {
            if(this.rawValue.charAt(textOffset - 1) == TimeInput.Separator) {
                this.rawValue = this.rawValue.substr(0, textOffset - 1) + this.rawValue.substr(textOffset);
                textOffset--;
            }
        }

        this.rawValue =  this.sanitizeInput();
        let hms = TimeService.stringToHms(this.rawValue);
        this.totalSeconds = TimeService.hmsToSeconds(hms);

        // Normalize formatting when there's no more to type
        if(textOffset != null && textOffset >= 8) {
            this.rawValue = TimeService.hmsToString(hms);
        }

        this.update();

        if(textOffset != null && element != null) {

            // Defer changing selection to next event cycle
            setTimeout(() => { 
                let newOffset = textOffset!;

                if(this.rawValue.charAt(newOffset) == TimeInput.Separator)
                    newOffset++;

                element!.selectionStart = newOffset; 
                element!.selectionEnd = newOffset; 
            }, 0);
        }
    }

    public handleBlur(event: any) {
        if(this.rawValue == "") {
            this.totalSeconds = null;
            this.update();
            this.$emit('blur', event.target.value);
            return;
        }

        let sanitized = this.sanitizeInput();
        let hms = TimeService.stringToHms(sanitized);
        this.totalSeconds = TimeService.hmsToSeconds(hms);

        // Normalize formatting on blur
        this.rawValue = TimeService.hmsToString(hms);
        this.update();
        this.$emit('blur', event.target.value);
    }

    public handleKeydown(event: KeyboardEvent) {
        // Handle backspacing ending separator
        if(event.code == "Backspace" && this.rawValue.charAt(this.rawValue.length - 1) == TimeInput.Separator) {
            this.rawValue = this.rawValue.substr(0, this.rawValue.length - 1);
        }

        if(event.ctrlKey) return;

        if(this.validationKeys.indexOf(event.code) !== -1) {
            this.handleBlur(event);
        }

        if(this.validSeparators.indexOf(event.code) !== -1) {
            event.preventDefault();
            this.rawValue = this.rawValue + TimeInput.Separator;
            this.handleInput(event);
        }

        if(this.validKeys.indexOf(event.code) === -1) {
            event.preventDefault();
        }
    }

    public sanitizeInput() {
        let vals = this.rawValue.split(TimeInput.Separator).filter(f => f.length);

        for(var i = 0; i < vals.length; i++) {
            var rawVal = vals[i];
            var strippedVal = "";

            for(var j = 0; j < rawVal.length; j++) {
                var code = rawVal.charCodeAt(j);
                // keep only digits in each span
                if(code >= 48 && code <= 57) {
                    strippedVal += rawVal.charAt(j);
                }
            }

            vals[i] = strippedVal;
        }

        let formatted = vals.slice(0, 3).join(TimeInput.Separator);

        if(vals.length < 3 && (vals[vals.length-1].length == 2 || this.rawValue.charAt(this.rawValue.length - 1) == TimeInput.Separator))
        {
            formatted += TimeInput.Separator;
        }

        return formatted;
    }
}

