
import { Options, Vue } from "vue-class-component";
import { AuthService, UserRegistrationRequest } from "@/api";
import OnAsync from "@/directives/OnAsync";

@Options({
    directives: {
        OnAsync,
    },
})
export default class Register extends Vue {
    register: UserRegistrationRequest = {};
    errorMessage: string | null = null;
    hasTouchedEmail = false;
    userRegistered = false;

    emailMismatch = false;
    confirmEmailAddress = "";

    private emailPattern = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

    async submit() {
        if(this.invalid) {
            this.errorMessage = "All fields are required";
        }

        try {
            let resp = await AuthService.register(this.register);
            this.userRegistered = true;
        } catch(ex: any) {
            this.register = {};
            this.errorMessage = ex.body.toString();
            this.$toast.error("Registration failed, try again");
        }
    }

    emailChanged() {
        this.hasTouchedEmail = true;

        if(this.register.EmailAddress != this.confirmEmailAddress) {
            this.emailMismatch = true;
        } else {
            this.emailMismatch = false;
        }
    }

    get invalid() {
        var valid =
            this.register.Username &&
            this.register.EmailAddress &&
            this.emailValid &&
            !this.emailMismatch &&
            this.register.Password &&
            this.register.ConfirmPassword &&
            this.register.Password == this.register.ConfirmPassword;

        return valid ? undefined : true;
    }

    get emailValid() {
        return this.emailPattern.test(this.register.EmailAddress!);
    }
}
