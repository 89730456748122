/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum Claim {
    NONE = 'None',
    READ = 'Read',
    SELF_READ_WRITE = 'SelfReadWrite',
    USERS_READ_WRITE = 'UsersReadWrite',
    APPROVE_GLOBAL = 'ApproveGlobal',
    APPROVE_LIMITED = 'ApproveLimited',
    MODIFY_STREAM_LIST = 'ModifyStreamList',
    SUBMIT_RUNS = 'SubmitRuns',
    MODIFY_RUNS_GLOBAL = 'ModifyRunsGlobal',
    MODIFY_RUNS_LIMITED = 'ModifyRunsLimited',
    EDIT_GAMES_GLOBAL = 'EditGamesGlobal',
    EDIT_GAMES_LIMITED = 'EditGamesLimited',
    MANAGE_PERMISSIONS = 'ManagePermissions',
}