
import { IntegrationsService, IntegrationVideoInfo } from "@/api";
import { Options, Vue, Prop, Watch } from "vue-decorator";

@Options({})
export default class VideoLinkInput extends Vue {
    @Prop()
    public modelValue: any;

    @Prop()
    public placeholder: string | undefined = undefined;

    @Prop()
    public required: boolean = false;

    public showResults = false;
    public recentVideos: any[] | null = [];
    public loading = false;
    public value: any = null;
    public expandedStyle = {
        width: "auto"
    };

    public rawInput: string | undefined | null = null;

    public async mounted() {
        this.load();
    }

    public queryFocus(e: FocusEventInit & Event) {
        if(e != null) {
            this.updateExpandedStyle(e.target!);
        }
        
        if((this.recentVideos?.length ?? 0) > 0)
            this.showResults = true;
    }

    public async inputChanged(e: Event) {
        this.$emit("update:modelValue", this.rawInput);
    }

    private async load(): Promise<void> {
        // TODO concat videos from twitch?
        this.loading = true;
        this.recentVideos = await IntegrationsService.youtubeRecentUploads();
        this.loading = false;
    }

    public select(value: any) {
        this.value = value;
        this.rawInput = null;
        this.$emit("update:modelValue", value.Url);
        this.showResults = false;
    }

    public clearSelected() {
        this.value = null;
        this.rawInput = null;
        this.$emit("update:modelValue", null);

        // Focus on next tick so that v-if adds element to DOM
        setTimeout(() => {
            (this.$refs as any).typeaheadInput.focus();
            this.showResults = true;
        }, 0);
    }

    get displayValue() {
        return this.value.Title.substr(0, Math.min(this.value.Title.length, 42))
         + (this.value.Title.length > 42 ? "..." : "");
    }

    public handleFocusOut(e: FocusEventInit & Event) {
        if(e.relatedTarget != null 
            && e.relatedTarget instanceof Node
            && e.currentTarget instanceof Node
            && e.currentTarget.contains(e.relatedTarget)) {
            return;
        }

        this.showResults = false;
    }

    private updateExpandedStyle(e: EventTarget) {
        let elem = e as HTMLElement;
        this.expandedStyle.width = elem.clientWidth + "px";
    }
}

