
import { Options, Vue } from "vue-class-component";
import AuthStore from "@/store/auth";
import OnAsync from "@/directives/OnAsync";
import { AuthenticationRequest, AuthType } from "@/api";

@Options({
    directives: {
        OnAsync
    }
})
export default class Login extends Vue {
    authReq: AuthenticationRequest = <AuthenticationRequest>{
         AuthType: AuthType.PASSWORD
    };
    errorMessage: string | null = null;

    async submit() {
        try {
            let resp = await AuthStore.login(this.authReq);

            if (resp) {
                this.errorMessage = null;
                this.authReq = <AuthenticationRequest>{};
                this.$router.push("/");
            } else {
                this.authReq.Secret = "";
                this.errorMessage = "Authentication failed, try again";
            }
        } catch (e) {
            this.authReq.Secret = "";
            this.errorMessage = "Authentication failed, try again";
        }
    }
}
