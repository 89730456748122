
import { Options, Vue, Prop, Watch } from "vue-decorator";
import { GameStore } from "@/store/games";
import { Game, Category, RunnableSegment } from "@/api";
import { RecordBoardKeySelectorConsts } from "./RecordBoardKeySelectorConsts";

@Options({
    emits: ["update"]
})
export default class LeaderboardKeySelector extends Vue {

    @Prop()
    readonly selectionContainer: any;

    @Prop()
    readonly showDifficulty: boolean = true;

    gameName : string | undefined | null = null;
    catName: string | undefined | null = null
    levelName : string | undefined | null = null
    diffName: string | undefined | null = null

    game: Game | undefined | null = null;
    games: Game[] = [];
    categories: Category[] = [];
    allLevels: RunnableSegment[] = [];
    levels: RunnableSegment[] = [];
    difficulties: string[] = [];

    async created() {
        await this.load();
    }

    mounted() {
        //if(this.selectionContainer == {}) {
        //    const storedKey = localStorage.getItem(RecordBoardKeySelectorConsts.LeaderboardPersistenceKey);
        //
        //    if(storedKey) {
        //        Object.assign(this.selectionContainer, JSON.parse(storedKey));
        //        this.$emit("update", this.selectionContainer);
        //    }
        //} else {
        //    this.persistSelection();
        //}
    }

    async load() {
        var gamestore = await GameStore.get();
        this.games = gamestore.games.filter(g => !g.Hidden);
        this.gameChanged();
        this.levelChanged();
        this.difficultyChanged();
    }

    @Watch("selectionContainer")
    onselectionContainerChanged(val: any, oldVal: any) {
        this.gameChanged();
        this.levelChanged();
        this.difficultyChanged();
        this.persistSelection();
    }

    persistSelection() {
        localStorage.setItem(RecordBoardKeySelectorConsts.LeaderboardPersistenceKey, JSON.stringify(this.selectionContainer));
    }

    gameChanged() {
        if (!this.selectionContainer.GameId){
            this.selectionContainer.GameId = this.games[0].Id;
        }

        this.game = this.games.find((g) => g.Id === this.selectionContainer.GameId);

        if(!this.game) {
            this.game = this.games[0];
            this.selectionContainer.GameId = this.game.Id;
        }

        this.gameName = this.game.Name;

        this.categories = this.game?.Categories ?? [];
        this.allLevels = this.game?.RunnableSegments ?? [];
        this.difficulties = this.game?.Difficulties ?? [];

        if(!this.categories.find(c => c.Id == this.selectionContainer.CategoryId)) {
            this.selectionContainer.CategoryId = this.categories[0]?.Id;
        }

        this.categoryChanged();
    }

    categoryChanged() {
        let newLevels = this.allLevels;

        if (this.selectionContainer.CategoryId != null) {
            let cat = this.categories.find((c) => c.Id === this.selectionContainer.CategoryId);

            if(!cat) {
                cat = this.categories[0];
            }

            this.catName = cat.Name;
            this.difficulties = cat.Difficulties ?? this.game?.Difficulties ?? [];

            newLevels = this.allLevels.filter(
                (l) => cat!.AllowedSegments!.indexOf(l.Id!) >= 0
            );
        }

        let same =
            Array.isArray(this.levels) &&
            Array.isArray(newLevels) &&
            this.levels.length === newLevels.length &&
            this.levels.every((val, index) => val.Id === newLevels[index].Id);

        if (!same) {
            this.levels = newLevels;
        }

        if(!this.levels.find(c => c.Id == this.selectionContainer.RunnableSegmentId)){
            this.selectionContainer.RunnableSegmentId = this.levels[0].Id;
            this.levelChanged();
        }

        if(!this.difficulties.find(c => c == this.selectionContainer.Difficulty)){
            this.selectionContainer.Difficulty = this.difficulties[0];
            this.difficultyChanged();
        }

        this.$emit("update", this.selectionContainer);
        this.persistSelection();
    }

    levelChanged() {
        let level = this.levels.find(l => l.Id == this.selectionContainer.RunnableSegmentId);
        this.levelName = level?.Name;

        this.$emit("update", this.selectionContainer);
    }

    difficultyChanged() {
        this.diffName = this.selectionContainer.Difficulty;
        this.$emit("update", this.selectionContainer);
    }

    get normalCats() {
        return this.categories?.filter(c => !c.IsExtension);
    }

    get extensionCats() {
        return this.categories?.filter(c => c.IsExtension);
    }
}
