
import { Options, Vue } from "vue-class-component";
import SubmitForm from "@/components/Submit/SubmitForm.vue";
import SubmitSelector, { SubmitSelection } from "@/components/Submit/SubmitSelector.vue";
import Markdown from 'vue3-markdown-it';
import { NavigationGuardNext, onBeforeRouteUpdate, RouteLocationNormalized, RouteParamValueRaw } from "vue-router";
import { GameStore } from "@/store/games";
import { Game } from "@/api";
import Collapsible from "@/directives/Collapsible";

@Options({
    components: {
        SubmitForm,
        SubmitSelector,
        Markdown
    },
    directives: {
        Collapsible
    },
    beforeRouteLeave: async function (t,f,n) { return await this.beforeLeave(t,f,n) },
    beforeRouteUpdate: async function (t,f,n) { return await this.beforeLeave(t,f,n) }
})
export default class SubmitView extends Vue {
    selection: SubmitSelection | null = null;
    effectiveSelection: SubmitSelection | null = null;
    submission: any = null;
    games: Game[] = [];

    public async created() {
        this.games = (await GameStore.get()).games;

        this.loadFromParams();
        this.applySelection();

        if(this.effectiveSelection)
            this.replaceRoute(this.effectiveSelection);
    }

    public loadFromParams() {
        let gameStub = this.getParam("game");
        let catStub = this.getParam("category");
        let difficulty = this.getParam("difficulty");

        let selection = {} as SubmitSelection;

        let game = this.games.find(g => g.UrlStub == gameStub);
        if(!game) {
            game = this.games[0];
        }

        let cat = game.Categories?.find(c => c.UrlStub == catStub);
        if(!cat) {
            cat = game.Categories![0];
        }

        if(!game.Difficulties?.find(d => d == difficulty)) {
            difficulty = game.Difficulties![0];
        }

        selection.game = game;
        selection.category = cat;
        selection.difficulty = difficulty;

        this.selection = selection;
    }

    public updateSubmitSelection(sel: SubmitSelection) {
        this.replaceRoute(sel);
    }

    public applySelection() {
        this.effectiveSelection = Object.assign({}, this.selection);
    }

    public revertSelection() {
        this.selection = Object.assign({}, this.effectiveSelection);
    }

    public replaceRoute(sel: SubmitSelection) {
        if(!sel) return;

        this.$router.replace({name: this.$route.matched[0].name, params: {
            game: sel.game!.UrlStub as RouteParamValueRaw,
            category: sel.category!.UrlStub as RouteParamValueRaw,
            difficulty: sel.difficulty as RouteParamValueRaw,
        }})
    }

    public async beforeLeave (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
        if(await this.guardExit()) {
            next();
            // In case we're just switching submit context, apply the selection
            this.applySelection();
        } else {
            // Stay here, make sure selector is reverted back to what's current
            this.revertSelection();
            next(false);
        }
    }


    public async guardExit(): Promise<boolean> {
        if(this.submission?.inProgress) {
            let resp = await this.$confirm({ 
                message: "Are you sure you want to leave? You have a submission in progress", 
                confirmAlias: "Yes", 
                cancelAlias: "No, stay here"
            });

            return !!resp;
        }

        return true;
    }

    getParam(name: string) : string | null {
        let val = this.$route.params[name];
        if(typeof val == "string") {
            return val;
        } else if (Array.isArray(val)) {
            return val[0];
        } else { 
            return null;
        }
    }
}
