import { OpenAPI } from "./api";
import store from "./store";

export default class Configuration
{
    public static DefaultApiBase: string = "http://localhost:7071";
    public static DefaultStaticContentBase: string = "http://localhost:5000";
    public static DefaultDiscordClientID: string = "891921284307509268";
    public static DefaultTwitchClientID: string = "fuvtgvyu2aytl4cms7b1dfi5vqk1i5";
    public static DefaultYoutubeClientID: string = "735118290596-gopdtu0r0n1u4qcjuc0miml4g1qc04jp.apps.googleusercontent.com";
    public static DefaultXboxClientID: string = "4817b03e-a2bd-4888-b015-def78cc6bfb0";
    public static DefaultRedirectURIBase: string = "http://localhost:8080";
    public static DefaultAppInsightsConnection: string = "InstrumentationKey=2ae59a3d-1cbc-4d4b-9aab-7cf581af89ed;IngestionEndpoint=https://centralus-0.in.applicationinsights.azure.com/";
    private static configInstance: Configuration;

    private _apiBase: string = Configuration.DefaultApiBase;
    private _staticContentBase: string = Configuration.DefaultStaticContentBase;
    private _discordClientID: string = Configuration.DefaultDiscordClientID;
    private _twitchClientID: string = Configuration.DefaultTwitchClientID;
    private _youtubeClientID: string = Configuration.DefaultYoutubeClientID;
    private _xboxClientID: string = Configuration.DefaultXboxClientID;
    private _redirectURIBase: string = Configuration.DefaultRedirectURIBase;
    private _appInsightsConnection: string = Configuration.DefaultAppInsightsConnection;

    static get ApiBase() { return Configuration.get()._apiBase; }
    static get StaticContentBase() { return Configuration.get()._staticContentBase; }

    static get DiscordClientID() { return Configuration.get()._discordClientID; }
    static get TwitchClientID() { return Configuration.get()._twitchClientID; }
    static get YoutubeClientID() { return Configuration.get()._youtubeClientID; }
    static get XboxClientID() { return Configuration.get()._xboxClientID; }
    static get RedirectURIBase() { return Configuration.get()._redirectURIBase; }
    static get AppInsightsConnection() { return Configuration.get()._appInsightsConnection; }

    public static get() {
        if(Configuration.configInstance != null) {
            return Configuration.configInstance;
        }

        var config = new Configuration();

        if(process.env.VUE_APP_ApiBase != null) {
            config._apiBase = process.env.VUE_APP_ApiBase;
        }

        if(process.env.VUE_APP_StaticContentBase != null) {
            config._staticContentBase = process.env.VUE_APP_StaticContentBase;
        }

        if(process.env.VUE_APP_DiscordClientID != null) {
            config._discordClientID = process.env.VUE_APP_DiscordClientID;
        }

        if(process.env.VUE_APP_TwitchClientID != null) {
            config._twitchClientID = process.env.VUE_APP_TwitchClientID;
        }

        if(process.env.VUE_APP_YoutubeClientID != null) {
            config._youtubeClientID = process.env.VUE_APP_YoutubeClientID;
        }

        if(process.env.VUE_APP_XboxClientID != null) {
            config._xboxClientID = process.env.VUE_APP_XboxClientID;
        }

        if(process.env.VUE_APP_RedirectURIBase != null) {
            config._redirectURIBase = process.env.VUE_APP_RedirectURIBase;
        }

        if(process.env.VUE_APP_AppInsightsConnection != null) {
            config._appInsightsConnection = process.env.VUE_APP_AppInsightsConnection;
        }

        OpenAPI.BASE = config._apiBase;
        OpenAPI.TOKEN = async () => store.state.auth.jwt ?? "";

        Configuration.configInstance = config;
        return config;
    }
}