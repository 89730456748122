
import { Options, Vue } from "vue-class-component"
import Authenticating from "@/components/Authenticating.vue"
import { IntegrationsService } from "@/api";


@Options({
    components: {Authenticating}
})
export default class SteamAuth extends Vue {
    async created() {
        let claimed = this.$route.query["openid.claimed_id"];

        if(claimed && typeof claimed == "string") {
            let steamId = claimed.substring(claimed.lastIndexOf("/")+1);

            try {
                await IntegrationsService.linkSteam({SteamId: steamId});
                this.$toast.success("Linked steam account!");
            } catch {
                this.$toast.error("Unable to link steam account, please try again");
            }
        } else {
            this.$toast.error("Unable to link steam account, unexpected data from Steam - likely a site bug");
        }

        setTimeout(() => this.$router.push({name: 'User Profile', params: {'username': this.$store.state.auth.username}}), 1000);
    }
}
