
import { Game, ProfilesService, StaticContentService, UserCareer, UserProfile } from "@/api";
import AuthStore from "@/store/auth";
import { GameStore } from "@/store/games";
import { Options, Vue } from "vue-class-component";
import { Watch } from "vue-decorator";

@Options({})
export default class Profile extends Vue {
    public username = "";
    public user: UserProfile | null | undefined = null;
    public isOwnProfile: boolean | undefined = false;
    public career: UserCareer | null = null;
    public games: Game[] = [];

    async created() {
        this.games = (await GameStore.get()).games;
        await this.load();
    }

    @Watch("$route.params.username")
    async onRouteChange() {
        await this.load();
    }

    async load() {
        this.isOwnProfile = false;
        this.username = this.$route?.params?.username?.toString();

        if(!this.username) {
            this.user = null;
            return;
        }

        try {
            this.user = await StaticContentService.userProfile(this.username);
        } catch {
            await AuthStore.pendingAuth;
            this.user = await ProfilesService.getUserProfile(this.username);
        }

        if (this.user?.UserId == null) return;
        try {
            this.career = await StaticContentService.userCareer(this.user.UserId);
        } catch (e: any) {
            this.$toast.error("Unable to load user career: " + e.toString());
        }

        try { 
            await AuthStore.pendingAuth; 
            this.isOwnProfile = this.username == this.$store.state?.auth?.username;
        } catch {}
    }
}
