
import { Category, Game, RunnableSegment } from "@/api";
import { GameStore } from "@/store/games";
import { Options, Vue } from "vue-class-component";
import Markdown from 'vue3-markdown-it';
import TimeInput from "@/components/Input/TimeInput.vue";
import TimeService from "@/services/TimeService";

@Options({
    components: {
        TimeInput,
        Markdown
    }
})
export default class Calc extends Vue {
    public games: Game[] = [];
    public selectedGame: Game | undefined | null = null;
    public selectedCategory: Category | undefined | null = null;
    public categoryLevels: (RunnableSegment & {Duration: number})[] = [];
    public routeComponents: {name: string, path: string}[] = [];

    async created() {
        this.games = (await GameStore.get()).games;

        this.loadRoute();
    }

    get levelDurationSum() {
        var totalSeconds = this.categoryLevels.reduce((e,l) => e + (l.Duration || 0), 0);
        return TimeService.secondsToString(totalSeconds);
    }

    public loadRoute() {
        let gameStub = this.$route.params["game"];
        let categoryStub = this.$route.params["category"];

        if(gameStub && typeof gameStub == "string") {
            this.selectedGame = this.games.find(g => g.UrlStub == gameStub);
        }

        if(this.selectedGame?.Categories && categoryStub && typeof categoryStub == "string") {
            this.selectedCategory = this.selectedGame.Categories.find(g => g.UrlStub == categoryStub);
        }

        if(this.selectedGame?.Categories?.length && !this.selectedCategory) {
            this.selectedCategory = this.selectedGame.Categories[0];
            // todo fix route
        }

        this.categoryLevels = [];
        for(let id of this.selectedCategory?.AllowedSegments ?? []) {

            let segment = this.selectedGame?.RunnableSegments?.find(s => s.Id == id) as (RunnableSegment & {Duration: number});

            // todo: il flag on segment
            if(!segment || segment.Points! > 100) {
                continue;
            }

            this.categoryLevels.push(segment);
        }

        this.routeComponents = [{
            path: "/calc",
            name: "Game Calculators"
        }];

        if(this.selectedGame) {
            let last = this.routeComponents[this.routeComponents.length - 1];
            this.routeComponents.push({
                path: last.path + "/" + this.selectedGame.UrlStub,
                name: this.selectedGame.Name!
            })
        }

        if(this.selectedGame && this.selectedCategory) {
            let last = this.routeComponents[this.routeComponents.length - 1];
            this.routeComponents.push({
                path: last.path + "/" + this.selectedCategory.UrlStub,
                name: this.selectedCategory.Name!
            })
        }
    }
}


