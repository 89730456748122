/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RunSubmission } from '../models/RunSubmission';
import type { RunSubmissionRequest } from '../models/RunSubmissionRequest';
import type { RunSubmissionSet } from '../models/RunSubmissionSet';
import { request as __request } from '../core/request';

export class RunSubmissionsService {

    /**
     * @returns RunSubmissionSet Success
     * @throws ApiError
     */
    public static async getSubmissions(): Promise<RunSubmissionSet> {
        const result = await __request({
            method: 'GET',
            path: `/api/RunSubmissions`,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static async submitRun(
requestBody?: RunSubmissionRequest,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/api/RunSubmissions`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @returns RunSubmission Success
     * @throws ApiError
     */
    public static async getRecentSubmissions(): Promise<Array<RunSubmission>> {
        const result = await __request({
            method: 'GET',
            path: `/api/RunSubmissions/recent`,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static async submitRuns(
requestBody?: Array<RunSubmissionRequest>,
): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/api/RunSubmissions/bulk`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param runSubmissionId 
     * @returns any Success
     * @throws ApiError
     */
    public static async verifySubmission(
runSubmissionId: string,
): Promise<any> {
        const result = await __request({
            method: 'PUT',
            path: `/api/RunSubmissions/${runSubmissionId}/verify`,
        });
        return result.body;
    }

    /**
     * @param runSubmissionId 
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static async rejectSubmission(
runSubmissionId: string,
requestBody?: string,
): Promise<any> {
        const result = await __request({
            method: 'PUT',
            path: `/api/RunSubmissions/${runSubmissionId}/reject`,
            body: requestBody,
        });
        return result.body;
    }

}