/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Game } from '../models/Game';
import type { GameDto } from '../models/GameDto';
import { request as __request } from '../core/request';

export class GamesService {

    /**
     * @returns Game Success
     * @throws ApiError
     */
    public static async getAllGames(): Promise<Array<Game>> {
        const result = await __request({
            method: 'GET',
            path: `/api/Games`,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns Game Success
     * @throws ApiError
     */
    public static async upsertGame(
requestBody?: GameDto,
): Promise<Game> {
        const result = await __request({
            method: 'POST',
            path: `/api/Games`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody 
     * @returns Game Success
     * @throws ApiError
     */
    public static async upsertGame1(
requestBody?: GameDto,
): Promise<Game> {
        const result = await __request({
            method: 'PUT',
            path: `/api/Games`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param identifier 
     * @returns Game Success
     * @throws ApiError
     */
    public static async getGame(
identifier: string,
): Promise<Game> {
        const result = await __request({
            method: 'GET',
            path: `/api/Games/${identifier}`,
        });
        return result.body;
    }

}