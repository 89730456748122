
import { Category, Game, Run, RunnableSegment } from "@/api";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-decorator";

@Options({})
export default class UserCareerGameLevel extends Vue {
    @Prop()
    runs: any[] = [];

    @Prop()
    game: Game | null = null;

    @Prop()
    category: Category | null = null;

    @Prop()
    difficulty: string | null = null;

    @Prop()
    level: RunnableSegment | null = null;

    @Prop()
    editable: boolean = false;

    get levelRuns() {
        return this.runs.filter((r) => r.RunnableSegmentId == this.level?.Id && r.Difficulty == this.difficulty);
    }
}
