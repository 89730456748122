
import { Options, Vue } from "vue-class-component";
import { Prop, Watch } from "vue-decorator";
import UserCareerGame from "@/components/User/UserCareerGame.vue";
import { GameStore } from "@/store/games";
import { Game, Claim, UserCareer as UserCareerDto, UserProfile } from "@/api";
import OnAsync from "@/directives/OnAsync";
import Masonry from "@/directives/Masonry";
import { useToast } from "vue-toastification";
import UserProfileTabs from "@/components/User/UserProfileTabs.vue";
import { ContentLoader } from "vue-content-loader";

@Options({
    components: {
        UserCareerGame,
        UserProfileTabs,
        ContentLoader
    },
    directives: {
        OnAsync,
        Masonry
    }
})
export default class UserCareer extends Vue {
    chosenCategory: string | null = null;
    games: Game[] = [];
    toast = useToast();
    levelSearch: string | null = null;
    canEditRuns = false;

    @Prop()
    public user: UserProfile | null = null;

    @Prop()
    public career: UserCareerDto | null = null;

    @Prop()
    public isOwnProfile: boolean = false;

    get catOrder() {
        return Object.keys(this.career?.RunsByCategory!).sort((a, b) => {  
            if (a == "Solo") return -1;
            if (b == "Solo") return 1;
            if (a == "Coop") return -1;
            if (b == "Coop") return 1;
            return 0;
        });
    }

    async mounted() {
        this.games = (await GameStore.get()).games.filter(g => !g.Hidden);

        await this.load();
    }

    @Watch("career")
    public async watchCareer() {
        await this.load();
    }

    async load() {
        var hrClaims = this.$store.state.auth.hrClaimStrings;

        this.canEditRuns = (this.$store.state.auth.claims.userId == this.user?.UserId && hrClaims.indexOf(Claim.SELF_READ_WRITE) > 0)
            || hrClaims.indexOf(Claim.MODIFY_RUNS_GLOBAL) > 0
            || hrClaims.indexOf(Claim.MODIFY_RUNS_LIMITED) > 0;

        if(this.career?.RunsByCategory == null) return;

        this.chosenCategory = this.catOrder[0];
    }

    get totalPoints() {
        if(this.career?.RankInfoByCategory == null) return;

        return Object.values(this.career.RankInfoByCategory).reduce((a,r) => a + (r?.Points! || 0), 0)
    }

    get totalTrophies() {
        if(this.career?.RankInfoByCategory == null) return;

        return Object.values(this.career.RankInfoByCategory).reduce((a,r) => a + (r?.GoldCount! || 0) + (r?.SilverCount! || 0) + (r?.BronzeCount! || 0), 0)
    }

    get categoryGames() {
        return this.games.filter(g => g.Categories && g.Categories.filter(c => c.Name == this.chosenCategory).length > 0);
    }
}
